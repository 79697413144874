export enum GoodType {
  "SERVICE" = "SERVICE",
  "PRODUCT" = "PRODUCT",
}

export enum GoodSubType {
  "INSTALL" = "INSTALL",
  "WASH" = "WASH",
  "PRODUCT" = "PRODUCT",
  "HOTEL" = "HOTEL",
  "CARSERVICE" = "CARSERVICE",
  "PROMOTION" = "PROMOTION"
}

export const GoodSubTypeTranslationMap: Record<GoodSubType, string> = {
  [GoodSubType.CARSERVICE]: 'Autószervíz',
  [GoodSubType.HOTEL]: 'Gumihotel',
  [GoodSubType.INSTALL]: ' Gumiszervíz',
  [GoodSubType.PRODUCT]: 'Termékek',
  [GoodSubType.WASH]: 'Mosó',
  [GoodSubType.PROMOTION]: 'Promóciós termékek'
}

export interface RelatedGood {
  id: number; 
  sourceSku: string; 
  relatedGoodSku: string; 
  createdAt: string; 
  updatedAt: string; 
}

export interface Good {
  id: number;
  sku: string;
  type: GoodType;
  subType: GoodSubType;
  title: string;
  description: string;
  enabled: boolean;
  stock: number;
  price: number;
  vat: number;
  discount: number;
  createdAt?: string;
  updatedAt?: string;
  barcode: string;
  color: string;
  minutes: number;
  isPack: boolean;
  category: string;
  relatedGoods?: RelatedGood[];
}

export interface SelectableGood extends Good {
  quantity?: number;
  position?: string;
  forcePositionSelection?: boolean;
}
