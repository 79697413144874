export interface User {
  id: number;
  name: string;
  email: string;
  enabled: boolean;
  role: string;
  createdAt: string;
  updatedAt: string;
  iat: number;
  exp: number;
  password?: string;
  passwordRepeat?: string;
}

export class UserClass implements User {
  id: number = 0;
  name: string = "";
  email: string = "";
  enabled: boolean = true;
  role: string = "";
  createdAt: string = "";
  updatedAt: string = "";
  iat: number = 0;
  exp: number = 0;
  password?: string = "";
  passwordRepeat: string = "";
  constructor() {}
}

export interface UserAuthenticated extends User {
  access_token: string;
}

export interface UserLink {
  id: number;
  userId: number;
  email: string;
  name: string;
  hash: string;
  used: boolean;
  expires: number;
}

export enum UserRoles {
  "SONGOKU" = "SONGOKU",
  "ADMIN" = "ADMIN",
}
