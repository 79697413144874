<div class="elements-container">
    <!-- Payment method -->
    <label>Fizetési mód</label>
    <div class="elements">
        <div  class="element" *ngFor="let button of paymentMethodButtons" (click)="setActivePaymentMethod(button)"
            [ngClass]="{'active':button.selected, 'disabled':!button.enabled}">
            <div class="head">{{button.title}}</div>
            <div class="body credit-card">
                <img [src]="button.icon" alt="{{button.title}}">
            </div>
        </div>
    </div>


    <label>Formátum</label>
    <div class="elements">
        <div  class="element" *ngFor="let button of billingFormatButtons" (click)="setActiveBillingFormat(button)"
            [ngClass]="{'active':button.selected, 'disabled':!button.enabled}">
            <div class="head">{{button.title}}</div>
            <div class="body credit-card">
                <img [src]="button.icon" alt="{{button.title}}">
            </div>
        </div>
    </div>

</div>