<div class="billing-type-selector">
    <button [ngClass]="{'selected':selectedValue === billingMethods.RECEIPT}" (click)="selectMethod(billingMethods.RECEIPT)">
        <span class="title">Nyugta</span>
    </button>
    <button [ngClass]="{'selected':selectedValue === billingMethods.BILL}" (click)="selectMethod(billingMethods.BILL)">
        <span class="title">Számla</span>
    </button>
    <button [ngClass]="{'selected':selectedValue === billingMethods.BILL_COLLECTION}" (click)="selectMethod(billingMethods.BILL_COLLECTION)">
        <span class="title">Gyűjtő számla</span>
    </button>
    
</div>