import { Component, EventEmitter, Output } from '@angular/core';
import { SessionService } from '../../../services/session.service';
import { MessageService } from 'src/app/services/message/message.service';

@Component({
  selector: 'app-session-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.less']
})
export class SearchComponent {

  public showGroupBillingOptionsPanel = false;

  @Output() toggleValue: EventEmitter<string> = new EventEmitter();

  constructor(private readonly sessionService: SessionService, private readonly ms: MessageService) { }

  private showGroupBillingResult(closed: number): void {

    if (closed === 0) {
      this.ms.createMessage(`A megadott adószámhoz jelenleg nem tartozik lezárásra váró munkamenet. Nincs további teendő`, 'info');
      return;
    }

    if (closed > 0) {
      this.ms.createMessage(`${closed} db munkamenet került átadásra számlázásra! A munkamenetek lezárásra kerültek.`, 'success');
      return;
    }
  }

  public indicateGroupBilling(taxNumber: string): void {
    this.sessionService.indicateGroupBilling(taxNumber).subscribe({
      next: (result) => { this.showGroupBillingResult(result.closed); this.showGroupBillingOptionsPanel = false; },
      error: (e) => console.error(e)
    })
  }

  public isValidTaxNumber(term: string): boolean {
    const regex1 = /\d{8}-\d{1}-\d{2}/gm;
    const regex2 = /\d{8}\d{1}\d{2}/gm;
    return regex1.test(term) || regex2.test(term);
  }

  public publish(term: string) {
    this.showGroupBillingOptionsPanel = this.isValidTaxNumber(term);
    this.toggleValue.emit(term);
  }
}
