import { Component } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs";

@Component({
  selector: "app-breadcrumb",
  templateUrl: "./breadcrumb.component.html",
  styleUrls: ["./breadcrumb.component.less"],
})
export class BreadcrumbComponent {
  public elements: string[] = [];
  public breadcrumb = "";
  public dictionary: Record<string, string> = {
    user: "felhasználó",
    session: "munkamenet",
    list: "lista",
    edit: "szerkesztés",
    create: "létrehozás",
    manage: "kezelés",
    good: "termék - szolgáltatás",
    profile: "személyes beállítások",
    content: "tartalom",
    "list?filter=scheduled": "Ütemezett munkamenetek",
    "list?filter=history":"Korábbi munkamenetek"
  };

  constructor(private readonly router: Router) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        this.elements = [];
        const e = event as unknown as NavigationEnd;
        const segments = e.url.split("/").filter(Boolean);
        segments.forEach((segment: any) => {
          const value = isNaN(segment) ? this.dictionary[segment] : segment;
          this.elements.push(value);
        });
        this.breadcrumb = this.elements.join(" / ");
      });
  }
}
