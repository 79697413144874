<div class="login-container" *ngIf="form">
    <h4>Elfelejtett jelszó helyreállítása</h4>
    <p>A megadott E-mail címre küldünk egy helyreállítási linket, amivel újjelszót adhat meg a
        bejelentkezéshez.</p>
    <form [formGroup]="form">
        <div class="form-group">
            <div><label for="email">E-mail cím</label></div>
            <div><input formControlName="email" type="email" name="email" id="email" required></div>
        </div>
        <div class="form-group">
            <button class="button" (click)="send()" [disabled]="!form.valid">Jelszó helyreállítása</button>
        </div>
        <div class="form-group text-center">
            <a class="link-secondary" [routerLink]="['/user/login']">Vissza a bejelentkezéshez</a>
        </div>
    </form>
</div>