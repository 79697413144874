import { Component, OnDestroy, OnInit } from "@angular/core";
import { AuthService } from "./modules/user/services/auth.service";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Subscription, filter, map, mergeMap, retry } from "rxjs";
import { CameraService } from "./services/camera/camera.service";
import { CameraSnapshot } from "./services/camera/camera.interface";
import { MessageService } from "./services/message/message.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.less"],
})
export class AppComponent implements OnInit, OnDestroy {
  public showNavigation: boolean = true;
  public cameraEventSubscription: Subscription | undefined;

  constructor(
    private readonly authService: AuthService,
    private readonly cameraService: CameraService,
    private readonly messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.authService.setIsAuthenticatedObservable();
  }

  private rootRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }

  ngOnDestroy(): void {
    this.cameraEventSubscription?.unsubscribe();
  }

  public cameraEventListener: EventSource;

  ngOnInit(): void {

    const cameraSnapshoEventListener = ({ data }: any) => {
      const snapshot = JSON.parse(data) as CameraSnapshot;
      if (snapshot) {
        const licencePlateNumber = snapshot.Picture.Plate.PlateNumber;
        this.messageService.createToast(
          `<p><b>Új jármű érkezését érzékeltem</b></p>
      <p><a href="/session/create?licenseNumber=${licencePlateNumber}">Új munkamenet létrehozása</a></p>`
        );
      }
    }

    // unsubscribe and close SSE connection before reconnect
    if (this.cameraEventListener) {
      this.cameraEventListener.close();
      this.cameraEventListener.removeEventListener('new-snapshot', cameraSnapshoEventListener);
    }

    this.cameraEventListener = this.cameraService.listenCameraSnapshots();
    this.cameraEventListener.onerror = (e: any) => {
      console.error(e);
    };

    this.cameraEventListener.addEventListener("new-snapshot", cameraSnapshoEventListener);

    /// ------

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.rootRoute(this.route)),
        filter((route: ActivatedRoute) => route.outlet === "primary"),
        mergeMap((route: ActivatedRoute) => route.data)
      )
      .subscribe((event: { [name: string]: any }) => {
        this.showNavigation = event["showNavigation"];
        if (('subType' in event)) {
          if (this.cameraEventListener) {
            this.cameraEventListener.close();
            this.cameraEventListener.removeEventListener('new-snapshot', cameraSnapshoEventListener);
          }
        }
      });
  }
}
