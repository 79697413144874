import { Observable, Subject } from "rxjs";
import { ICartItem } from "./cart-item";

export type ICart = {
  getId: () => string;
  appendItem: (item: ICartItem) => ICart;
  removeItem: (item: ICartItem) => ICart;
  getCartTotal: () => number;
  getCartElements: () => ICartItem[];
  updateTotalValue: () => ICart;
  getChangeEventObservable: () => Observable<Cart>;
};

export class Cart implements ICart {
  private defaultTotal: number = 0;
  private currentTotal: number = 0;
  private elements: ICartItem[] = [];

  private changeEventSubject = new Subject<Cart>();
  private changeEventObservable = this.changeEventSubject.asObservable();

  constructor(
    public readonly id: string,
    public readonly initialElements: ICartItem[] = []
  ) {
    // add initial elements
    this.initialElements.forEach((element: ICartItem) =>
      this.appendItem(element)
    );

    this.updateTotalValue();
  }

  public getChangeEventObservable(): Observable<Cart> {
    return this.changeEventObservable;
  }

  public updateTotalValue(): Cart {
    this.currentTotal = this.elements.reduce(
      (n, element) => n + element.calculatePrice(),
      this.defaultTotal
    );
    return this;
  }

  public getCartElements(): ICartItem[] {
    return this.elements;
  }

  public getCartTotal(): number {
    return this.currentTotal;
  }

  public getId(): string {
    return this.id;
  }

  public appendItem(item: ICartItem): Cart {
    // find previously added cart item with same id and summarize the quantity of the elements
    const previousElement = this.elements.find((element: ICartItem) => element.id === item.id && element.position === item.position);
    if (previousElement) {
      previousElement.quantity += item.quantity;
      this.updateTotalValue();
      this.exposeChangeEvent(this);
      return this;
    }

    this.elements.push(item);
    this.updateTotalValue();
    this.exposeChangeEvent(this);

    return this;
  }

  public removeItem(item: ICartItem): Cart {
    const index = this.elements.findIndex(
      (storedItem: ICartItem) => storedItem.id === item.id
    );
    if (index !== -1) {
      this.elements.splice(index, 1);
    }
    this.updateTotalValue();
    this.exposeChangeEvent(this);
    return this;
  }

  private exposeChangeEvent(message?: any) {
    this.changeEventSubject.next(message);
  }
}
