<div class="wrapper">
    <div class="service-list">
        <div class="header">Csomagok</div>
        <div class="item" (click)="addProductToCart('packeco')">> ECO + Eco</div>
        <div class="item" (click)="addProductToCart('packextr')">> Extra + Protect</div>
        <div class="item" (click)="addProductToCart('packsuv')">> SUV + Protect</div>
        <div class="item" (click)="addProductToCart('packperf')">> Perf + Nano</div>
        <div class="header">Tárhely</div>
        <app-warehouse></app-warehouse>
    </div>
    <div class="tabs">

        <div class="tab-header">
            <div class="tab-header-title">
                Előzmények
            </div>
            <div class="filters" *ngIf="goods.length > 0">
                <span class="filter-button" [ngClass]="{'active':filters.includes(availableFilters.WASH)}"
                    (click)="setFilter(availableFilters.WASH)"><i class="fa fa-shower"></i> Mosó</span>
                <span class="filter-button" [ngClass]="{'active':filters.includes(availableFilters.INSTALL)}"
                    (click)="setFilter(availableFilters.INSTALL)"><i class="fa fa-car-side"></i> Gumiszervíz</span>
                <span class="filter-button" [ngClass]="{'active':filters.includes(availableFilters.CARSERVICE)}"
                    (click)="setFilter(availableFilters.CARSERVICE)"><i class="fa fa-screwdriver-wrench"></i>
                    Autószervíz</span>
                <span class="filter-button" [ngClass]="{'active':filters.includes(availableFilters.PRODUCT) || filters.includes(availableFilters.PROMOTION)}"
                    (click)="setFilter(availableFilters.PRODUCT)"><i class="fa fa-shop"></i> Termékek</span>
            </div>
        </div>
        <div class="tab-contents" *ngIf="loading">
            <div class="tab-content">
                <div style="text-align: center;padding:20px;">
                    <i class="fa fa-spinner fa-spin"></i> Betöltés folyamatban...
                </div>
            </div>
        </div>

        <div class="tab-contents" *ngIf="!loading">
            <div class="tab-content">
                <div *ngIf="goods.length > 0">
                    <table>
                        <thead>
                            <tr>
                                <th>Cikkszám</th>
                                <th>Megnevezés</th>
                                <th>Ár</th>
                                <th>Készlet</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr (click)="selectProduct(good)" *ngFor="let good of visibleGoods">
                                <td>{{good?.sku}}</td>
                                <td>{{good?.title || 'N/A'}}</td>
                                <td>{{good?.price}}</td>
                                <td>{{good?.stock}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div *ngIf="goods.length === 0">
                    <div style="text-align: center;padding:20px;">
                        <i class="fa fa-ban"></i> Nincs megjeleníthető előzmény...
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>