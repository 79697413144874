<div class="image-wrapper">
    <div class="image-container">
        <img src="/assets/vehicle.svg" alt="">
    </div>
    <div class="selector-wrapper">
        <div class="selector-area half-size text-left top-16"><span *ngIf="positions.FRONT_LEFT" class="fa fa-caret-right arrow"></span></div>
        <div class="selector-area half-size text-right top-16"><span *ngIf="positions.FRONT_RIGHT" class="fa fa-caret-left arrow"></span></div>
        <div class="selector-area third-size text-left"><span *ngIf="positions.REAR_LEFT" class="fa fa-caret-right arrow"></span></div>
        <div class="selector-area third-size text-center top-24"><span *ngIf="positions.REAR_CENTER" class="fa fa-caret-up arrow"></span></div>
        <div class="selector-area third-size text-right"><span *ngIf="positions.REAR_RIGHT" class="fa fa-caret-left arrow"></span></div>
    </div>
</div>