import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  GoodListComponent,
  GoodTypePipe,
} from "./components/good-list/good-list.component";
import { GoodCreateComponent } from "./components/good-create/good-create.component";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { GoodService } from "./services/good.service";

@NgModule({
  declarations: [
    GoodListComponent,
    GoodCreateComponent,
    GoodTypePipe,
  ],
  providers: [GoodService],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
  ],
})
export class GoodModule { }
