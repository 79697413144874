export enum PaymentMethod {
    'CREDIT_CARD' = 'CREDIT_CARD',
    'BANK_TRANSFER' = 'BANK_TRANSFER',
    'CASH' = 'CASH',
    'CHIP_CARD' = 'CHIP_CARD',
    'VOUCHER' = 'VOUCHER'
}

export const PaymentMethodMap: Record<PaymentMethod, string> = {
    [PaymentMethod.BANK_TRANSFER]: 'Átutalás',
    [PaymentMethod.CASH]: 'Készpénz',
    [PaymentMethod.CREDIT_CARD]: 'Bankkártya',
    [PaymentMethod.CHIP_CARD]: 'Chipkártya',
    [PaymentMethod.VOUCHER]: 'Utalvány'
}