import { Pipe, PipeTransform } from "@angular/core";
import { WheelPosition, wheelPositionShortMap } from "../services/session.interface";

@Pipe({ name: "servicePositionType" })
export class ServicePosititionTypePipe implements PipeTransform {
    transform(value: string): string {

        if (!value) { return value; }

        const elements = value.split(',');

        return elements.map((item) => {
            switch (item) {
                case wheelPositionShortMap[WheelPosition.FRONT_LEFT]:
                    return 'BE';
                case wheelPositionShortMap[WheelPosition.FRONT_RIGHT]:
                    return 'JE'
                case wheelPositionShortMap[WheelPosition.REAR_LEFT]:
                    return 'BH';
                case wheelPositionShortMap[WheelPosition.REAR_RIGHT]:
                    return 'JH';
                case wheelPositionShortMap[WheelPosition.REAR_CENTER]:
                    return 'PÓT';
                default:
                    return item;
            };
        }).join(',');

    }
}