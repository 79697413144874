<div>

    <div class="filter-toolbar">
        <a [routerLink]="['/session/create']" class="button">Új munkamenet</a>
        <app-session-search (toggleValue)="filter($event)"></app-session-search>
        <div style="display:flex;gap:10px;">

            <a class="button button-outline" [routerLink]="['/session/list']">Aktív munkamenetek</a>
            <a class="button button-outline" [routerLink]="['/session/list']"
                [queryParams]="{filter:'scheduled'}">Ütemezett munkamenetek</a>
            <a class="button button-outline" [routerLink]="['/session/list']" [queryParams]="{filter:'history'}">Korábbi
                munkamenetek</a>
        </div>
    </div>
</div>
<div *ngIf="list.raw.length > 0">
    <table>
        <thead>
            <tr>
                <th><i class="fa fa-edit"></i></th>
                <td><i class="fa fa-bank"></i></td>
                <th>ID</th>
                <th>Rendszám</th>
                <th>Név</th>
                <th>Cím</th>
                <th>Telefon</th>
                <th>Adószám</th>
                <th>Létrehozva</th>
                <th>Utolsó módosítás</th>
                <th class="text-center">Állapot</th>
                <th class="text-center">Idő</th>

            </tr>
        </thead>
        <tbody *ngIf="list.filtered.length > 0">
            <tr *ngFor="let session of list.filtered">
                <td width="40">
                    <app-dropdown-button [title]="'...'">
                        <ul>
                            <li><a [routerLink]="['/session/edit/',session.id]"><i
                                        class="fa fa-file"></i>Szerkesztés</a></li>
                            <li><a (click)="openModalWindow('presenter', session.id)"><i
                                        class="fa fa-eye"></i>Bemutatás</a></li>
                            <li><a (click)="sendTextMessage(session)"><i class="fa fa-message"></i>SMS küldése</a></li>
                            <li><a (click)="deleteSession(session.id)"><i class="fa fa-times"></i>Törlés</a></li>
                            <li><a (click)="setSessionAsBillable(session.id)"
                                    *ngIf="session.billingMethod === billingMethods.BILL && session.billingFormat === billingFormats.PAPER "><i
                                        class="fa fa-file-invoice"></i>Beküldés számlázásra</a></li>
                            <li><a (click)="closeSession(session.id)"
                                    *ngIf="session.paymentMethod === paymentMethods.BANK_TRANSFER "><i
                                        class="fa fa-check"></i>Átutalással rendezve, lezárható</a></li>
                        </ul>
                    </app-dropdown-button>
                </td>
                <td width="24">
                    <i *ngIf="session.paymentMethod === paymentMethods.BANK_TRANSFER" class="fa fa-bank"></i>
                </td>
                <td width="100">{{session.id}}</td>
                <td width="200"><b>{{session.plate}}</b></td>
                <td>{{session.name}}</td>
                <td>{{session.zipcode}} {{session.city}} {{session.address}}</td>
                <td>{{session.phone | phoneFormat}}</td>
                <td>{{session.taxnumber}}</td>
                <td>{{session.createdAt}}</td>
                <td>{{session.updatedAt}}</td>
                <td class="text-center">{{session.sessionState | sessionState}}</td>
                <td width="100" style="text-align: center;">
                    <span *ngIf="session.sessionState === 'ACTIVE'"><app-session-countdown
                            [session]="session"></app-session-countdown></span>
                </td>

            </tr>
        </tbody>
        <tbody *ngIf="!list.raw.length">
            <tr>
                <td colspan="5">Nincs megjeleníthető adat...</td>
            </tr>
        </tbody>
    </table>
</div>

<div class="modal" *ngIf="modalOpened=='presenter'">
    <div class="modal-content modal-content-wide">
        <div class="modal-header">
            <h3>Bemutatás</h3>
            <span (click)="closeModalWindow();" class="close">&times;</span>
        </div>
        <div class="modal-body">
            <app-presenter [sessionId]="selectedSessionId"></app-presenter>
        </div>
    </div>
</div>