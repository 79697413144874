<div class="login-container">
    <h4>Jelszó változtatás</h4>
    <p>Adja meg új jelszavát amit a bejelentkezéshez használhat majd.</p>
    <form [formGroup]="form" (ngSubmit)="save()">
        <div>
            <input type="password" name="password-1" id="password-1" placeholder="Új jelszó..." formControlName="password"  required autocomplete="new-password">
        </div>
        <div>
            <input type="password" name="password-2" id="password-2" placeholder="Új jelszó mégegyszer..." formControlName="passwordRepeat" required autocomplete="new-password">
        </div>
        <div>
            <button type="submit">Új jelszó használata</button>
        </div>
    </form>
</div>