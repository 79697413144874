import { Component, Input, OnInit } from '@angular/core';
import { ISession } from '../../../services/session.interface';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.less']
})
export class ProductListComponent {
  @Input() session: ISession | undefined;
}
