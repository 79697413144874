import { ICart } from "./cart";

export class CartPersistence {

  /**
   * Get item from storage
   */
  public static getItem(id: string): string | null {
    return window.sessionStorage.getItem(id);
  }

  /**
   * Set item in storage
   */
  public static setItem(id: string, data: ICart): void {
    window.sessionStorage.setItem(id, JSON.stringify(data));
  }

  /**
   * Removes item from storage
   */
  public static removeItem(id: string): void {
    window.sessionStorage.removeItem(id);
  }
}
