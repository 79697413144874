import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Gate, ISession, SessionState } from 'src/app/modules/session/services/session.interface';
import { SessionService } from 'src/app/modules/session/services/session.service';

@Component({
  selector: 'app-present-session-summary',
  templateUrl: './present-session-summary.component.html',
  styleUrls: ['./present-session-summary.component.less']
})
export class PresentSessionSummaryComponent implements OnInit, OnDestroy {

  @Input() sessionId: number | undefined;
  public session: ISession | undefined;
  public refreshTimeout: number = 1000;
  public refreshInterval: any | undefined = undefined;
  public modalVisible: boolean = false;

  constructor(private readonly sessionService: SessionService) { }

  public getGateSuffix(gate: number): string {
    switch (gate) {
      case Gate.SERVICE:
        return `${gate}-es`;
      case Gate.WASH:
        return `${gate}-ös`;
      default:
        return `${Gate.SERVICE}-es`;
    }
  }

  private clear(): void {
    clearInterval(this.refreshInterval);
  }

  private readSession(): void {
    if (this.sessionId) {
      this.sessionService.getSession(this.sessionId).subscribe({
        next: (session: ISession) => {
          if (session) {
            this.session = session;
            this.modalVisible = this.session.sessionState === SessionState.ACTIVE;
            if (this.session.sessionState === SessionState.ACTIVE) {
              this.clear();
            }
          }
        },
        error: (e) => { }
      })
    }
  }

  ngOnDestroy(): void {
    this.clear();
  }

  ngOnInit(): void {
    if (this.sessionId) {
      this.readSession();
      this.refreshInterval = setInterval(() => { this.readSession() }, this.refreshTimeout);
    }
  }
}
