import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Good, GoodSubType, GoodType, SelectableGood } from 'src/app/modules/good/services/good.interface';
import { SessionUtils } from '../utils/session-utils';
import { debounceTime, fromEvent } from 'rxjs';

enum StockFilterOptions {
  ALL = 'ALL',
  STOCK_ONLY = 'STOCK_ONLY'
}

@Component({
  selector: 'app-good-filter',
  templateUrl: './good-filter.component.html',
  styleUrls: ['./good-filter.component.less']
})
export class GoodFilterComponent extends SessionUtils implements OnInit, AfterViewInit {

  @Input() goodType: GoodType;
  @Input() goodSubType: GoodSubType;
  @Input() goods: Good[] = [];

  public stockFilterOptions = StockFilterOptions;

  public loading = false;

  public stockFilter: StockFilterOptions = StockFilterOptions.ALL;
  public textFilter: string = "";
  public categoryFilter: string = "";

  public categoryFiltersMap: Record<string, string[]> = {
    [GoodSubType.CARSERVICE]: ['karosszéria','elektromos','általános','futómű','fényezés','hajtáslánc','motor','hatósági','fék','diagnosztika','mentés','klíma'],
    [GoodSubType.INSTALL]: ['centrírozás','agro','csomag','felnijavítás','flotta','teher','gumijavítás','aluszerelés','gumiszerelés','acélszerelés','tárolás'],
    [GoodSubType.WASH]: ['mosóutca','önkiszolgáló'],
  };

  @ViewChild("filterInput", { static: true }) filterInput: | ElementRef<HTMLInputElement>;

  public list: { raw: SelectableGood[], filtered: SelectableGood[], inited: SelectableGood[] } = { raw: [], filtered: [], inited: [] }

  @Output() toggleValue: EventEmitter<SelectableGood> = new EventEmitter();

  constructor() { super(); }

  ngAfterViewInit(): void {
    fromEvent(this.filterInput.nativeElement, 'keyup').pipe(debounceTime(500)).subscribe({
      next: () => {
        this.textFilter = this.filterInput.nativeElement.value;
        this.filter()
      }
    })
  }

  ngOnInit(): void {
    this.list.raw = this.goods;
    this.initList();
    this.setInputFieldFocus(this.filterInput);
  }

  public setStockFilter(event: Event) {
    const target = event.target as HTMLSelectElement;
    this.stockFilter = target.value as StockFilterOptions;
    this.filter();
  }

  public setCategoryFilter(filter: string) {
    this.categoryFilter = this.categoryFilter === filter ? "" : filter;
    this.filter()
  }

  public initList() {
    const list = this.list.inited.length > 0 ? this.list.inited : this.prefilter(this.goods, this.goodType, this.goodSubType);
    this.list.inited = list;
    this.list.filtered = list;
  }

  public prefilter(goods: Good[], type: GoodType = GoodType.SERVICE, subType?: GoodSubType) {
    const typeFilter = (item: Good, value?: GoodType) => item.type && value ? item.type === value : true;
    const subTypeFilter = (item: Good, value?: GoodSubType) => item.subType && value ? item.subType === value : true;
    return goods.filter((item: Good) => (typeFilter(item, type) && subTypeFilter(item, subType))).slice(0, 100);
  }

  public filter() {

    this.loading = true;

    if (!this.textFilter || this.textFilter.length < 3) {
      if (!this.categoryFilter) {
        this.list.filtered = [];
        this.initList();
        this.loading = false;
        return;
      }
    }

    const keyword = this.textFilter?.toLowerCase();

    const titleFilter = (item: Good, keyword: string) => keyword ? item.title?.toLowerCase().includes(keyword) : true;
    const skuFilter = (item: Good, keyword: string) => keyword ? item.sku?.toLowerCase().includes(keyword) : true;
    const categoryFilter = (item: Good) => this.categoryFilter ? item.category?.toLowerCase() === this.categoryFilter?.toLowerCase() : true;
    const stockFilter = (item: Good) => this.stockFilter === StockFilterOptions.ALL ? true : item.stock > 0;

    this.list.filtered = this.list.raw.filter((item: Good) => categoryFilter(item) && (titleFilter(item, keyword) || skuFilter(item, keyword)))

    // apply stock filtering
    if (this.stockFilter !== StockFilterOptions.ALL) {
      this.list.filtered = this.list.filtered.filter((item: Good) => stockFilter(item));
    }

    this.loading = false;

  }

  public select(good: SelectableGood) {
    this.toggleValue.emit({ ...good, quantity: 1, forcePositionSelection: this.goodSubType === GoodSubType.INSTALL });
  }
}
