import { Component, Input } from '@angular/core';
import { ISession } from '../../../services/session.interface';

@Component({
  selector: 'app-session-details-photos',
  templateUrl: './photos.component.html',
  styleUrls: ['./photos.component.less']
})
export class PhotosComponent {
  @Input() session : ISession | undefined;
}
