<div class="container">
    <div class="row">
        <div class="column">
            <div>
                <div class="input-group-prepend">
                    <a class="button" [routerLink]="['/user/create']">Új felhasználó létrehozása</a>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="column">

            <input type="text" #searchTerm placeholder="A kereséshez gépeljen ebben a mezőben..."
                (keyup)="filter(searchTerm.value)">
            <table class="table">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Név</th>
                        <th>Email</th>
                        <th>Létrehozva</th>
                        <th colspan="2">Művelet</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let user of usersFiltered;">
                        <td>{{user.id}}</td>
                        <td>{{user.name}}</td>
                        <td>{{user.email}}</td>
                        <td>{{user.createdAt}}</td>
                        <td><a [routerLink]="['/user/edit',user.id]">Szerkesztés</a></td>
                    </tr>
                </tbody>
            </table>

        </div>
    </div>
</div>