export enum BillingFormat {
    'ELECTRONIC' = 'ELECTRONIC',
    'PAPER' = 'PAPER',
    'RECEIPT' = 'RECEIPT',
}

export const BillingFormatMap: Record<BillingFormat, string> = {
    [BillingFormat.ELECTRONIC]: 'E-számla',
    [BillingFormat.PAPER]: 'Papír',
    [BillingFormat.RECEIPT]: 'Nyugta',
}