import { Component, HostListener, Input } from '@angular/core';

@Component({
  selector: 'app-dropdown-navigation-button',
  templateUrl: './dropdown-navigation-button.component.html',
  styleUrls: ['./dropdown-navigation-button.component.less'],
})
export class DropdownNavigationButtonComponent {

  @Input() title : string | undefined;
  @Input() icon: string | undefined;

  public insideClick = false;

  public toggled = false;
  public toggle(): void {
    this.toggled = !this.toggled;
  }

  @HostListener('click')
  clickInside() {
    this.insideClick = true;
    this.toggle();
  }

  @HostListener('document:click')
  clickout() {
    if (!this.insideClick) {
      this.toggled = false;
    }
    this.insideClick = false;
  }
}
