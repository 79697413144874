import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/modules/user/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.less'],
})
export class NavigationComponent implements OnInit {
  public appName: string = environment.appName;
  public isAuthenticated: boolean = false;

  constructor(private readonly authService: AuthService) {}

  ngOnInit(): void {
    this.authService.getIsAuthenticatedObservable().subscribe({
      next: (isAuthenticated) => {
        this.isAuthenticated = isAuthenticated;
      },
      error: (e) => console.error(e),
    });
  }
}
