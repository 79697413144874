<div class="row">
    <div class="column" *ngIf="!selectedSession">
        <div>
            <label for="session-filter-input">Munkamenet keresése</label>
            <input type="text" name="session-filter-input" id="session-filter-input" #filterInput
                (keyup)="filter(filterInput.value)">
        </div>
        <div class="element-list">
            <label>Keresés eredménye</label>
            <table class="cart-items">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Típus</th>
                        <th>Rendszám</th>
                        <th>Telefon</th>
                        <th>Név</th>
                        <th>Adószám</th>
                        <th>Végösszeg</th>
                        <th>Dátum</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of list.filtered" (click)="showDetails(item)">
                        <td>{{item.id}}</td>
                        <td>{{item.sessionType}}</td>
                        <td><b>{{item.plate}}</b></td>
                        <td>{{item.phone}}</td>
                        <td>{{item.name}}</td>
                        <td>{{item.taxnumber}}</td>
                        <td><b>{{item.totalPrice | number}}</b></td>
                        <td>{{item.createdAt}}</td>
                    </tr>
                </tbody>
            </table>


        </div>
    </div>
    <div class="column" *ngIf="selectedSession">

        <div class="toolbar">
            <button (click)="backToList()" class="button button-outline">Vissza a találatokhoz</button>
            <button (click)="select(selectedSession)">Ügyfél adatok felhasználása</button>
            <button (click)="select(selectedSession, true)">Munkamenet megismétlése</button>
        </div>

        <div class="element-list">

            <div class="row">
                <div class="column column-50">
                    <label for="">Munkamenet részletei</label>
                    <table class="cart-items">
                        <tbody>
                            <tr>
                                <td width="140">ID</td>
                                <td>{{selectedSession.id}}</td>

                            </tr>

                            <tr>
                                <td>Rendszám</td>
                                <td><b>{{selectedSession.plate}}</b></td>
                            </tr>

                            <tr>
                                <td>Név</td>
                                <td>{{selectedSession.name}}</td>

                            </tr>

                            <tr>
                                <td>Telefon</td>
                                <td>{{selectedSession.phone}}</td>
                            </tr>

                            <tr>
                                <td>Irányítószám</td>
                                <td>{{selectedSession.zipcode}}</td>

                            </tr>

                            <tr>
                                <td>Város</td>
                                <td>{{selectedSession.city}}</td>
                            </tr>


                            <tr>
                                <td>Utca, házszám</td>
                                <td>{{selectedSession.zipcode}}</td>

                            </tr>

                            <tr>
                                <td>Adószám</td>
                                <td>{{selectedSession.taxnumber}}</td>
                            </tr>

                            <tr>
                                <td>Végösszeg</td>
                                <td><b>{{selectedSession.totalPrice | number}}</b></td>
                            </tr>

                            <tr>
                                <td>Létrehozva</td>
                                <td>{{selectedSession.createdAt}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="column column-50">
                    <label>Termékek / szolgáltatások</label>
                    <table class="cart-items">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Megnevezés</th>
                                <th>Mennyiség</th>
                                <th>Egységár</th>
                                <th>Összesen</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let product of selectedSession.products">
                                <td>{{product.id}}</td>
                                <td>{{product.title}}</td>
                                <td><b>{{product.quantity | number}}</b></td>
                                <td><b>{{product.unitPrice | number}}</b></td>
                                <td><b>{{product.totalPrice | number}}</b></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>