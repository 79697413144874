import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, NgModel, Validators } from "@angular/forms";
import {
  BehaviorSubject,
  debounceTime,
  firstValueFrom, fromEvent, lastValueFrom,
} from "rxjs";
import { ICart } from "src/app/services/cart/cart";
import { CartItem, ICartItem } from "src/app/services/cart/cart-item";
import { CartService } from "src/app/services/cart/cart.service";
import { Good, GoodSubType, GoodSubTypeTranslationMap, GoodType, RelatedGood, SelectableGood } from "src/app/modules/good/services/good.interface";
import {
  Gate,
  ISession,
  ISessionProduct,
  SessionState,
  SessionType,
  WheelPosition,
  wheelPositionShortMap,
} from "../../services/session.interface";
import { SessionService } from "../../services/session.service";
import { MessageService } from "src/app/services/message/message.service";
import { CameraService } from "src/app/services/camera/camera.service";
import { GoodService } from "src/app/modules/good/services/good.service";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { SessionUtils } from "./utils/session-utils";
import { provideNgxMask } from "ngx-mask";
import { City, Street, ZipcodeService } from "src/app/services/zipcode/zipcode.service";
import { ContentService } from "src/app/modules/content/services/content.service";
import { getRelatedWorkstationId } from "src/app/utils/identify-machine";
import { TextMessageService } from "src/app/services/text-message/text-message.service";
import { PaymentMethod } from "src/app/utils/payment-methods";
import { BillingFormat } from "src/app/utils/billing-formats";
import { BillingMethod } from "src/app/utils/billing-methods";
import { environment } from "src/environments/environment";
import { calculatePrice } from "src/app/utils/calculate-price";

interface EditableCartItem extends CartItem {
  priceToForce: number;
}

@Component({
  selector: "app-session-create",
  templateUrl: "./session-create.component.html",
  styleUrls: ["./session-create.component.less"],
  providers: [
    provideNgxMask({})
  ]
})
export class SessionCreateComponent extends SessionUtils implements OnInit, AfterViewInit {

  /// Definitions

  public cart: ICart;
  public cartId: string = "DEFAULT_CART_ID";
  public sessionHistory: ISession[] = [];
  public form: FormGroup;
  public modalOpened: string = '';
  public goodSubTypeTranslationMap = GoodSubTypeTranslationMap;
  public goods: Good[] = [];

  @ViewChild("zipcodeInput", { static: true }) zipcodeInput: | ElementRef<HTMLInputElement>;
  @ViewChild("plateInput", { static: true }) plateInput: | ElementRef<HTMLInputElement>;

  private defaultFormInitializer: ISession = {
    phone: "",
    email: "",
    city: "",
    zipcode: "",
    address: "",
    name: "",
    taxnumber: "",
    plate: "",
    totalCount: 0,
    totalPrice: 0,
    products: [],
    sessionState: SessionState.DRAFT,
    sessionType: SessionType.SERVICE,
    hash: "",
    hunterLink: "",
    invoiceLink: "",
    invoiceNumber: "",
    gate: Gate.DEFAULT as unknown as number,
    paymentMethod: PaymentMethod.CREDIT_CARD,
    vehicleManufacturedYear: undefined,
    billingFormat: BillingFormat.ELECTRONIC,
    billingMethod: BillingMethod.BILL,
    readyToSymbol: true,
    milage: undefined

  };

  public streets: Street[] = [];

  public taxNumberValidationEnabled = true;

  public taxdataLoading = false;

  public initialPaymentMethod: PaymentMethod | undefined = undefined;
  public initialBillingFormat: BillingFormat | undefined = undefined;

  private escapeKeyHandler = this.escapeHandler.bind(this);

  /// Methods

  constructor(
    private readonly cartService: CartService,
    private readonly goodService: GoodService,
    private readonly sessionService: SessionService,
    private readonly messageService: MessageService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    private readonly zipcodeService: ZipcodeService,
    private readonly contentService: ContentService,
    private readonly textMessageService: TextMessageService
  ) {
    super();
    this.cartService.removeCart(this.cartId);
    this.cart = this.cartService.createCart(this.cartId);
  }

  ngAfterViewInit(): void {

    fromEvent(this.plateInput.nativeElement, 'keyup').pipe(debounceTime(300)).subscribe({
      next: () => {
        this.readLastCustomerByPlate();
      }
    })

    fromEvent(this.zipcodeInput.nativeElement, 'keyup').pipe(debounceTime(300)).subscribe({
      next: () => {

        const zipcode = this.zipcodeInput.nativeElement.value;

        if (zipcode.length !== 4) { return; }

        this.zipcodeService.findCity(zipcode).subscribe({
          next: (cityResponse: City | null) => {

            if (cityResponse) {

              const { city } = cityResponse;

              this.form.get('city')?.setValue(city);

              this.zipcodeService.findStreets(zipcode, city).subscribe({
                next: (streets: Street[]) => {
                  this.streets = streets;
                },
                error: (e) => console.error(e)
              });
            }

          },
          error: (e) => console.error(e)
        });
      }
    });

  }

  private async fetchSession(id: number): Promise<ISession> {
    return firstValueFrom(this.sessionService.getSession(id));
  }

  private async getRouteParams(): Promise<Params> {
    return firstValueFrom(this.route.params);
  }

  public setVehicleAge(age: string): void {
    this.form.get('vehicleManufacturedYear')?.setValue(age);
  }

  public setPaymentAndBillingMethod({ paymentMethod, billingFormat }: { paymentMethod: PaymentMethod | undefined, billingFormat: BillingFormat | undefined }): void {
    this.form.get('billingFormat')?.setValue(billingFormat);
    this.form.get('paymentMethod')?.setValue(paymentMethod);
  }

  public selectedGoodSubType: GoodSubType;
  public selectedGoodType: GoodType;
  public hotLinkHandler({ type, subType }: { type: GoodType, subType: GoodSubType }) {
    this.selectedGoodSubType = subType;
    this.selectedGoodType = type;
    this.openModalWindow('service-selector');
  }

  public setBillingMethod(method: BillingMethod): void {

    const taxNumberFormField = this.form.get('taxnumber');

    this.form.get('billingMethod')?.setValue(method);

    // reset billing format and payment method
    this.form.get('paymentMethod')?.setValue(undefined);
    this.form.get('billingFormat')?.setValue(undefined);

    // Disable taxnumber field for receipt
    if (method === BillingMethod.RECEIPT) {
      taxNumberFormField?.disable();
      this.taxNumberValidationEnabled = false;
    }

    // Enable taxnumber field for bill
    if (method === BillingMethod.BILL || method === BillingMethod.BILL_COLLECTION) {
      this.taxNumberValidationEnabled = true;
      taxNumberFormField?.enable();
    }
  }

  private initializeForm(response: ISession): FormGroup {

    const { prefix, number } = this.splitPhoneNumber(response.phone);

    this.initialPaymentMethod = response.paymentMethod as PaymentMethod;
    this.initialBillingFormat = response.billingFormat as BillingFormat;

    return this.formBuilder.group({
      id: [response.id],
      plate: [response.plate, Validators.required],
      sessionType: [response.sessionType, Validators.required],
      sessionState: [response.sessionState, Validators.required],
      products: [response.products],
      name: [response.name],
      email: [response.email],
      phonePrefix: [prefix],
      phone: [number],
      zipcode: [response.zipcode],
      city: [response.city],
      address: [response.address],
      taxnumber: [response.taxnumber],
      paymentMethod: [response.paymentMethod],
      vehicleManufacturedYear: [response.vehicleManufacturedYear],
      billingFormat: [response.billingFormat],
      billingMethod: [response.billingMethod],
      readyToSymbol: [response.readyToSymbol],
      milage: [response.milage]
    });
  }

  public async resolveGoodSubTypes(goods: Array<ISessionProduct>) {
    const goodTypes: GoodSubType[] = [];
    for (const good of goods) {
      //const resolvedGood = await firstValueFrom(this.goodService.getGood(good.goodId));
      const resolvedGood = this.goods.find((item: Good) => item.id === good.goodId);
      if (resolvedGood && resolvedGood.type) { goodTypes.push(resolvedGood.subType) }
    }
    return goodTypes;
  }

  public async defineGateNumber(goods: Array<ISessionProduct>): Promise<number> {

    const goodSubTypes = await this.resolveGoodSubTypes(goods);

    // washing service found
    if (goodSubTypes.includes(GoodSubType.WASH)) { return Gate.WASH; }

    // installing service found
    if (goodSubTypes.includes(GoodSubType.INSTALL)) { return Gate.SERVICE; }

    // repair service found
    if (goodSubTypes.includes(GoodSubType.CARSERVICE)) { return Gate.SERVICE; }

    return Gate.DEFAULT;
  }

  public async validateSessionForm(session: any, goods: ISessionProduct[]): Promise<boolean> {

    // validate common fields
    if (session.gate !== Gate.WASH && !session.milage) {
      this.messageService.createMessage('A km állás megadása kötelező!', 'error'); return false;
    }

    if (!session.phone || session.phone === 'undefined' || session.phone === undefined) {
      this.messageService.createMessage('A telefonszám megadása kötelező!', 'error'); return false;
    }

    if (this.form.get('phone')?.invalid) {
      this.messageService.createMessage('A telefonszám formátuma érvénytelen!', 'error'); return false;
    }

    if (!session.phonePrefix || session.phonePrefix === 'undefined' || session.phonePrefix === undefined) {
      this.messageService.createMessage('A telefonszám előhívójának megadása kötelező!', 'error'); return false;
    }

    // validate billing data fields in case customer wants more than a receipt
    if (session.billingMethod !== BillingMethod.RECEIPT) {
      if (!session.name) {
        this.messageService.createMessage('A név megadása kötelező!', 'error'); return false;
      }
      if (!session.zipcode) {
        this.messageService.createMessage('Az irányítószám megadása kötelező!', 'error'); return false;
      }
      if (!session.city) {
        this.messageService.createMessage('A város megadása kötelező!', 'error'); return false;
      }
      if (!session.address) {
        this.messageService.createMessage('Az utca, házszám megadása kötelező!', 'error'); return false;
      }
    }

    if (!session.plate && session.sessionType !== SessionType.PURCHASE) {
      this.messageService.createMessage('A forgalmi rendszám megadása kötelező!', 'error'); return false;
    }

    if (!goods.length) {
      this.messageService.createMessage('A kosár üres! Adjon hozzá terméket vagy szolgáltatást!', 'error'); return false;
    }

    if (!session.vehicleManufacturedYear) {
      this.messageService.createMessage('A jármű korának megadása kötelező!', 'error'); return false;
    }

    // in case of electronic billing, email address is required
    if (session.billingFormat === BillingFormat.ELECTRONIC) {
      if (!session.email) {
        this.messageService.createMessage('Az E-mail cím megadása kötelező!', 'error'); return false;
      }
    }

    // in case of receipt show confirmation message when cart contains products also
    if (session.sessionType === SessionType.PURCHASE && session.billingMethod === BillingMethod.RECEIPT) {
      const confirmationResult = await this.messageService.createConfirmation('Valóban nyugtát szeretne készíteni annak ellenére, hogy a kosárban termék is van?','warning',false);
      if (!confirmationResult.isConfirmed) {return false;}

    }

    return true;
  }

  public async getSessionGoods() {

    const goods = this.cart.getCartElements().map((item) => {

      const totalPrice = item.calculatePrice();
      const unitVat = Number(((totalPrice / item.quantity) - item.price).toFixed(0));

      return {
        goodId: item.id,
        quantity: item.quantity,
        unitVat: unitVat,
        vat: item.vat,
        unitPrice: item.price,
        totalPrice: item.calculatePrice(),
        title: item.title,
        position: item.position,
        sku: item.sku
      } as ISessionProduct;
    });

    const totalPrice = this.cart.getCartTotal();
    const totalCount = goods.length;

    return {
      goods, totalPrice, totalCount
    }
  }

  public async confirmSessionStart(): Promise<boolean> {
    const result = await this.messageService.createConfirmation(`Az ügyfél jóváhagyta a munkamenetet?`, 'success', false);
    return result.isConfirmed;
  }

  public async hijackContentDisplay(sessionId: number) {
    const displayId = getRelatedWorkstationId();
    if (displayId && sessionId) {
      this.contentService.hijackDisplayContent(displayId, sessionId).subscribe({
        next: () => { },
        error: (e) => this.messageService.createMessage(e.message, 'error')
      });
    }
  }
  public async releaseContentDisplay() {
    const displayId = getRelatedWorkstationId();
    if (displayId) {
      this.contentService.releaseDisplayContent(displayId).subscribe({
        next: () => { },
        error: (e) => this.messageService.createMessage(e.message, 'error')
      })
    }
  }

  public async sendTextMessage(hash: string, phone: string) {
    return this.textMessageService.sendDetailsPageMessage(hash, phone);
  }

  public async start(saveOnly: boolean = false) {

    const { goods, totalCount, totalPrice } = await this.getSessionGoods();

    // be sure tax number is enabled before saving because tax number value needed to be stored
    // even if other billing method was selected if there is any value for this field
    this.form.get('taxnumber')?.enable();

    const session = this.form.value;
    session.totalPrice = totalPrice;
    session.totalCount = totalCount;

    // # define proper gate of entry
    session.gate = Gate.DEFAULT;
    const goodSubTypes = await this.resolveGoodSubTypes(goods);

    // In case of draft and scheduled sessions prepare proper states
    // this prevents saving mechanism to override actual state after its activated
    if (session.sessionState !== SessionState.ACTIVE) {

      // washing service found
      if (goodSubTypes.includes(GoodSubType.WASH)) {
        session.gate = Gate.WASH;
        session.sessionState = SessionState.DRAFT;
        session.sessionType = SessionType.SERVICE;
      }

      // installing service found
      if (goodSubTypes.includes(GoodSubType.INSTALL)) {
        session.gate = Gate.SERVICE;
        session.sessionState = SessionState.DRAFT;
        session.sessionType = SessionType.SERVICE;
      }

      // repair service found
      if (goodSubTypes.includes(GoodSubType.CARSERVICE)) {
        session.gate = Gate.SERVICE;
        session.sessionType = SessionType.SERVICE;
        session.sessionState = SessionState.DRAFT;
      }

      // only product selled
      if (goodSubTypes.every((item) => item === GoodSubType.PRODUCT || item === GoodSubType.PROMOTION)) {
        session.gate = Gate.DEFAULT;
        session.sessionState = SessionState.ACTIVE;
        session.sessionType = SessionType.PURCHASE;
        session.readyToSymbol = true;
      }

      // keep scheduled
      if (saveOnly) {
        session.sessionState = SessionState.SCHEDULED;
        session.readyToSymbol = false;
      }

    }

    // Validate form data
    const isFormValid = await this.validateSessionForm(session, goods);
    if (!isFormValid) {
      return;
    }

    // rebuild phone number
    const phone = this.concatenatePhoneNumber(this.form.value.phonePrefix, this.form.value.phone);

    this.sessionService.createSession({ session: { ...session, phone }, goods }).subscribe({
      next: async (result: ISession) => {

        const sessionId = result.id || 0;

        if (sessionId) {
          this.form.get("id")?.setValue(sessionId);
        }

        // copy hash to session object as this is generated on server side but still need on UI
        session.hash = result.hash;

        // Saving only
        if (saveOnly) {
          this.messageService.createMessage(`Munkamenet tárolása sikeres volt!`, 'success');
          this.router.navigate(['/session/create']);
          return;
        }

        if (!saveOnly) {

          await this.hijackContentDisplay(sessionId);

          const confirmedByCustomer = await this.confirmSessionStart();

          if (confirmedByCustomer) {
            this.sessionService
              .updateSession({
                ...result,
                sessionState: "ACTIVE",
              }).subscribe({
                next: async (value: ISession) => {

                  this.form.reset();
                  this.cartService.removeCart(this.cart.getId());
                  this.cart = this.cartService.createCart(this.cartId);

                  this.messageService.createMessage("Munkamenet sikeresen elindítva! Az ügyfél tovább hajthat.", 'success');

                  await this.sendTextMessage(session.hash, phone);
                  this.router.navigate(['/']);

                }, error: (e) => this.messageService.createMessage(e.message, 'error')
              })
          }

          await this.releaseContentDisplay();

        }
      },
      error: (error) =>
        this.messageService.createMessage(error.error.message, "error"),
    });
  }

  public cartItemToModify: CartItem | undefined;
  public cartItemToModifyForm: { quantity: number; price: number; } = { price: 0, quantity: 1 };

  public modifyCartItem(cartItem: CartItem) {

    this.cartItemToModify = cartItem;

    const { quantity, price, vat, discount } = cartItem;

    this.cartItemToModifyForm.price = calculatePrice(price, vat, discount, 1);
    this.cartItemToModifyForm.quantity = quantity;

    this.openModalWindow('cart-item-editor');
  }

  public isNonEditableGood(sku: string): boolean {
    return environment.nonEditableGoods.includes(sku);
  }

  public saveCartItemModifications(): void {

    if (!this.cartItemToModify) {
      return;
    }

    if (this.cartItemToModify?.quantity === 0) {
      this.messageService.createMessage('Érvénytelen mennyiség!', 'error');
      return;
    }

    const { id, title, position, discount, sku } = this.cartItemToModify;

    this.cart.removeItem(this.cartItemToModify);

    const newCartItem = new CartItem({
      id: id,
      title: title,
      vat: 0, // vat has to be zero as price defined as gross
      price: this.cartItemToModifyForm.price,
      discount: discount,
      quantity: this.cartItemToModifyForm.quantity,
      position: position,
      sku: sku
    });

    this.cart.appendItem(newCartItem);

    this.cart.updateTotalValue();
    this.cartItemToModify = undefined;
    this.closeModalWindow();
  }

  async readAllGoods(): Promise<void> {
    this.goods = await lastValueFrom(this.goodService.getGoods());
  }

  public dataLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  async ngOnInit(): Promise<void> {

    this.dataLoading.next(true);

    this.form = this.initializeForm(this.defaultFormInitializer);

    await this.readAllGoods();

    const { id } = await this.getRouteParams();

    if (id) {
      const sessionResponse = await this.fetchSession(id);
      this.form = this.initializeForm(sessionResponse);
      this.repeatSession({ session: sessionResponse, repeat: true });
    }

    this.setLicencePlateByParams();

    this.dataLoading.next(false);

  }

  public readLastCustomerByPlate(): void {
    const plate = this.form.get("plate")?.value;
    if (!plate) { return; }
    this.sessionService.searchSession({ where: { plate: plate }, limit: 1 }).subscribe({
      next: (sessions: ISession[]) => {
        if (sessions.length) {
          this.repeatSession({
            session: {
              ...sessions[0], // we override the session id here
              milage: 0 // be sure milage setted to zero as we dont know how many 'clicks' he drived that vehicle
            }, repeat: false
          });
        }
      },
      error: (e) => console.error(e)
    });
  }

  private setLicencePlateByParams(): void {
    const licencePlateNumber = this.route.snapshot.queryParamMap.get("licenseNumber");
    if (licencePlateNumber) {
      this.setLicensePlate(licencePlateNumber);
    }
  }

  public readLicensePlate(): void {
    this.openModalWindow('plate-selector');
  }

  public setLicensePlate(plate: string): void {

    plate = plate.replaceAll('-', '');
    this.form.get("plate")?.setValue(plate);
    this.closeModalWindow();
    this.readLastCustomerByPlate();

  }

  private escapeHandler(e: KeyboardEvent): void {
    if (e.key === "Escape") {
      this.closeModalWindow();
    }
  }

  public openModalWindow(id: string): void {
    this.modalOpened = id;
    document.addEventListener("keyup", this.escapeKeyHandler);
  }

  public closeModalWindow(): void {
    this.modalOpened = "";
    document.removeEventListener("keyup", this.escapeKeyHandler);
  }

  public createCartElement(sku: string) {
    const good = this.goods.find((good: Good) => good.sku === sku);
    this.appendToCart(good as SelectableGood);
  }

  private createCartItem(item: SelectableGood, quantity: number, position: string = ""): ICartItem {

    // override quantity based on selected positions
    if (position && !item.isPack) {
      const selectedPositions = position.split(',');
      quantity = selectedPositions.length;
    }

    // set position by default for packs
    if (!position && item.isPack) {

      const selectedPositions = Object.keys(WheelPosition).reduce((accum: Array<string>, current) => {
        if (current !== WheelPosition.REAR_CENTER) {
          accum.push(wheelPositionShortMap[current as keyof typeof WheelPosition])
        }
        return accum;
      }, []);
      position = selectedPositions.join(',')
    }

    // reset pending cart item quantity
    this.pendingCartItem = {
      ...this.pendingCartItem,
      quantity: 1,
    }

    return new CartItem({
      id: item.id,
      title: item.title,
      price: item.price,
      vat: item.vat,
      quantity: quantity,
      discount: item.discount,
      position: position,
      sku: item.sku,
    });
  }

  public appendToCart(item: SelectableGood) {

    const cartItem = this.createCartItem(item, item.quantity || 1, item.position);
    this.cart.appendItem(cartItem).updateTotalValue();

    // find and add related goods to the cart
    const good = this.goods.find((good: Good) => good.sku === item.sku);

    if (good && good.relatedGoods && Array.isArray(good.relatedGoods) && good.relatedGoods.length > 0) {

      good.relatedGoods.forEach((related: RelatedGood) => {
        const relatedGood = this.goods.find((good: Good) => good.sku === related.relatedGoodSku) as SelectableGood;
        if (relatedGood) {
          this.cart.appendItem(this.createCartItem(relatedGood, 1)).updateTotalValue()
        }
      })
    }

    this.cartService.storeCart(this.cartId, this.cart);
    this.closeModalWindow();
  }

  public pendingCartItem: SelectableGood;
  public insertCartElement(item: SelectableGood, manualInsert = false) {

    if (manualInsert && !item.isPack && item.forcePositionSelection) {
      this.openModalWindow('position-selector');
      this.pendingCartItem = item;
      return;
    }

    this.appendToCart(item);
  }

  public setPendingCartItemPosition(selectedPosition: string): void {
    this.pendingCartItem.position = selectedPosition;
  }

  public removeCartElement(item: ICartItem) {

    const { transitiveRelatedGoods } = environment;

    // delete related elements from cart
    const good = this.goods.find((good: Good) => good.sku === item.sku);

    // delete transitive related goods
    if (good && good.sku in transitiveRelatedGoods) {
      const transitiveGoodSku: string = transitiveRelatedGoods[good.sku];
      const transitive = this.goods.find((good: Good) => good.sku === transitiveGoodSku) as SelectableGood;
      if (transitive) {
        this.cart.removeItem(this.createCartItem(transitive, 1)).updateTotalValue();
      }
    }

    // delete related goods from cart
    if (good && good.relatedGoods && Array.isArray(good.relatedGoods) && good.relatedGoods.length > 0) {

      good.relatedGoods.forEach((related: RelatedGood) => {
        const relatedGood = this.goods.find((good: Good) => good.sku === related.relatedGoodSku) as SelectableGood;
        if (relatedGood) {
          this.cart.removeItem(this.createCartItem(relatedGood, 1)).updateTotalValue();
        }
      })
    }

    this.cart.removeItem(item).updateTotalValue();
    this.cartService.storeCart(this.cartId, this.cart);
  }


  public fillByTaxNumber(): void {

    if (this.form.get('taxnumber')?.invalid) {
      this.messageService.createMessage('Hibás adószám formátum!', 'error');
      this.taxdataLoading = false;
      return;
    }

    this.taxdataLoading = true;
    this.sessionService.getCompanyByTaxnumber(this.form.get('taxnumber')?.value).subscribe({
      next: (result: any) => {
        if (result.status !== 'OK') {
          this.messageService.createMessage(result.message, 'error');
          this.taxdataLoading = false;
          return;
        }
        this.taxdataLoading = false;

        // thank you NAV developers to give back 2 different data structure based on data length - motherfuckers
        const address = Array.isArray(result.details.taxpayerAddressList.taxpayerAddressItem) ? result.details.taxpayerAddressList.taxpayerAddressItem.find((item: any) => item.taxpayerAddressType === 'HQ') : result.details.taxpayerAddressList.taxpayerAddressItem;
        if (address) {
          this.form.get('name')?.setValue(result.details.taxpayerName);
          this.form.get('city')?.setValue(address.taxpayerAddress.city);
          this.form.get('zipcode')?.setValue(address.taxpayerAddress.postalCode);
          this.form.get('address')?.setValue(`${address.taxpayerAddress.streetName} ${address.taxpayerAddress.publicPlaceCategory} ${address.taxpayerAddress.number}`);
        }
      },
      error: (e) => {
        this.taxdataLoading = false;
        this.messageService.createMessage(e.message, 'error');
      }
    })
  }

  public repeatSession({ session, repeat }: { session: ISession, repeat: boolean }) {

    this.closeModalWindow();

    const { prefix, number } = this.splitPhoneNumber(session.phone);

    // use customer details

    if (repeat) {
      this.form.get('id')?.setValue(session.id);
    }

    this.form.get('name')?.setValue(session.name);
    this.form.get('phone')?.setValue(number);
    this.form.get('phonePrefix')?.setValue(prefix);
    this.form.get('zipcode')?.setValue(session.zipcode);
    this.form.get('address')?.setValue(session.address);
    this.form.get('city')?.setValue(session.city);
    this.form.get('taxnumber')?.setValue(session.taxnumber);
    this.form.get('email')?.setValue(session.email);
    this.form.get('vehicleManufacturedYear')?.setValue(session.vehicleManufacturedYear);
    this.form.get('billingMethod')?.setValue(session.billingMethod);
    this.form.get('billingFormat')?.setValue(session.billingFormat);
    this.form.get('paymentFormat')?.setValue(session.paymentMethod);
    this.form.get('milage')?.setValue(session.milage);

    if (repeat) {
      this.cartService.removeCart(this.cartId);
      this.cart = this.cartService.createCart(this.cartId);

      session.products.forEach((sessionProductItem: ISessionProduct) => {

        const good = this.goods.find((good: Good) => good.id === sessionProductItem.goodId);

        if (good) {

          // use price and vat of stored session good item
          good.price = sessionProductItem.unitPrice;
          good.vat = sessionProductItem.vat;

          const cartItem = this.createCartItem(good, sessionProductItem.quantity, sessionProductItem.position);
          this.cart.appendItem(cartItem).updateTotalValue();
        }

      });

      this.cartService.storeCart(this.cartId, this.cart);
    }
  }
}
