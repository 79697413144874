import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

export interface DisplaySetting {
    displayId: string;
    width: number;
    height: number;
}

@Injectable({
    providedIn: "root",
})
export class DisplayService {
    
    constructor(private readonly http: HttpClient) { }

    public getDisplaySettings(): Observable<DisplaySetting[]> {
        return this.http.get<DisplaySetting[]>(`${environment.api.hostname}/display-settings`);
    }

    public saveDisplaySettings(dto: DisplaySetting) {
        return this.http.post<DisplaySetting[]>(`${environment.api.hostname}/display-settings`, dto);
    }
}