<div class="loading-indicator" *ngIf="dataLoading.getValue()">
    <div class="loading-indicator-backdrop"></div>
    <div class="loading-indicator-text"> Adatok betöltése folyamatban... <i class="fa fa-spinner fa-spin"></i> </div>
</div>

<div class="container">
    <form [formGroup]="form">
        <div class="grid-row">

            <!-- Products / Services -->
            <div class="grid-col">

                <div class="row">
                    <div class="column">
                        <h4>Termékek / szolgáltatások</h4>
                    </div>
                </div>

                <!-- License plate -->


                <div class="row">
                    <div class="column">
                        <table class="no-style-table">
                            <tr>
                                <td colspan="2"><label for="plate">Forgalmi rendszám</label></td>
                                <td><label for="plate">Km állás</label></td>
                                <td colspan="2"><label for="plate">Jármű kora</label></td>
                            </tr>
                            <tr>
                                <td>
                                    <button class="action-button" name="get-plate" id="get-plate"
                                        (click)="readLicensePlate()">
                                        <span class="fa fa-car"></span>
                                        <span>Beolvasás</span>
                                    </button>
                                </td>
                                <td>
                                    <input type="text" formControlName="plate" autofocus name="plate" id="plate"
                                        oninput="this.value = this.value.toUpperCase()" #plateInput mask="A{30}"
                                        [dropSpecialCharacters]="true">
                                </td>

                                <td>
                                    <input type="number" formControlName="milage" autofocus name="milage" id="milage">
                                </td>
                                
                                <td colspan="2">
                                    <app-vehicle-age [age]="form.get('vehicleManufacturedYear')?.value"
                                        (toggleValue)="setVehicleAge($event)"></app-vehicle-age>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>


                <div class="row">
                    <div class="column">
                        <label>Előzmények</label>
                        <app-customer-history [goodList]="goods" (goodSelected)="insertCartElement($event, true)"
                            [plate]="form.get('plate')?.value"></app-customer-history>
                        <br>
                    </div>
                </div>

                <div class="row">
                    <div class="column">
                        <label>Gyorslinkek</label>
                        <app-hot-links (productSelected)="createCartElement($event)"
                            (toggleValue)="hotLinkHandler($event)"></app-hot-links>
                    </div>
                </div>

                <!-- Cart elements -->
                <div class="row">
                    <div class="column">
                        <br>
                        <label>Kosár</label>
                        <table class="cart-items">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>SKU</th>
                                    <th>Megnevezés</th>
                                    <th>Db</th>
                                    <th>Ár</th>
                                    <th>Poz</th>
                                    <th>Művelet</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="cart.getCartElements().length === 0">
                                    <td colspan="6">A kosár üres...</td>
                                </tr>
                                <tr class="cart-element-row" *ngFor="let cartItem of cart.getCartElements()">
                                    <td>{{cartItem.id}}</td>
                                    <td>{{cartItem.sku}}</td>
                                    <td>{{cartItem.title}}</td>
                                    <td>{{cartItem.quantity}}</td>
                                    <td class="price-text"><b>{{cartItem.calculatePrice() |
                                            number}}
                                            HUF</b></td>
                                    <td>{{cartItem.position | servicePositionType}}</td>
                                    <td>
                                        <div style="display:flex;gap:10px;">
                                            <a *ngIf="!isNonEditableGood(cartItem.sku)" class="remove-cart-item-btn" (click)="removeCartElement(cartItem)"><i
                                                    class="fa fa-times"></i></a>
                                            <a *ngIf="!isNonEditableGood(cartItem.sku)" class="edit-cart-item-btn" (click)="modifyCartItem(cartItem)"><i
                                                    class="fa fa-edit"></i></a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th colspan="3">Összesen</th>
                                    <th colspan="3">{{cart.getCartTotal() | number}} HUF</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>

            </div>

            <!-- Session details -->
            <div class="grid-col">
                <div class="row">
                    <div class="column">
                        <h4>Munkamenet adatok</h4>
                    </div>
                </div>

                <div class="row">
                    <div class="column">
                        <div class="row">
                            <div class="column">
                                <label for="taxnumber">Adószám</label>
                                <input type="text" formControlName="taxnumber" name="taxnumber" id="taxnumber"
                                    mask="00000000-0-00" [dropSpecialCharacters]="false" [showMaskTyped]="true">
                            </div>
                            <div class="column">
                                <label>&nbsp;</label>
                                <button
                                    [disabled]="taxdataLoading || !taxNumberValidationEnabled || form.get('taxnumber')?.value?.length === 0"
                                    class="action-button" (click)="fillByTaxNumber()">
                                    <span class="fa fa-question"></span>
                                    <span>Adószám lekérdezése</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <label>Számlázás módja</label>
                        <app-billing-method [initialValue]="form.get('billingMethod')?.value"
                            (toggleValue)="setBillingMethod($event)"></app-billing-method>
                    </div>
                </div>

                <div class="row">
                    <div class="column">
                        <app-payment-method (toggleValue)="setPaymentAndBillingMethod($event)"
                            [billingMethod]="form.get('billingMethod')?.value" [billingFormat]="initialBillingFormat"
                            [paymentMethod]="initialPaymentMethod"></app-payment-method>
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <label for="email">E-mail cím</label>
                        <input type="email" formControlName="email" name="email" id="email">
                    </div>
                </div>

            </div>

            <!-- Billing data -->
            <div class="grid-col">
                <div class="row">
                    <div class="column">
                        <h4>Számlázási adatok</h4>
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <label for="name">Név / Cégnév</label>
                        <input type="text" formControlName="name" name="name" id="name">
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <div>
                            <div class="row">
                                <div class="column">
                                    <label for="zipcode">Irányítószám</label>
                                    <input type="text" formControlName="zipcode" name="zipcode" id="zipcode"
                                        #zipcodeInput>
                                </div>
                                <div class="column">
                                    <label for="city">Város</label>
                                    <input type="text" formControlName="city" name="city" id="city">
                                </div>
                            </div>
                        </div>
                        <div>
                            <label for="address">Utca, házszám</label>
                            <input type="text" formControlName="address" name="address" id="address" list="streets">
                            <datalist id="streets">
                                <option *ngFor="let street of streets">{{street.street}}</option>
                            </datalist>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <label for="plate">Telefonszám</label>
                        <div class="row">
                            <div class="column">
                                <select name="phone-prefix" id="phone-prefix" size="4" formControlName="phonePrefix"
                                    style="height:auto">
                                    <option value="+3620">+36 20</option>
                                    <option value="+3630">+36 30</option>
                                    <option value="+3650">+36 50</option>
                                    <option value="+3670">+36 70</option>
                                </select>
                            </div>
                            <div class="column">
                                <input type="text" formControlName="phone" name="phone" id="phone" required
                                    mask="000-00-00" [showMaskTyped]="true">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <label class="cart-total-label">Összesen - {{cart.getCartTotal() | number}} HUF</label>
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <div style="display:flex;justify-content: space-between;gap:10px;">
                            <button (click)="start(true)" class="action-button"
                                style="width:100%;text-align: center;"><i class="fa fa-save"></i> Mentés</button>
                            <button [disabled]="form.get('sessionState')?.value === 'ACTIVE'" (click)="start()"
                                class="action-button" style="width:100%;text-align: center;"><i
                                    class="fa fa-money"></i>Fizetés és SMS</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </form>
</div>

<div class="modal service-selector" *ngIf="modalOpened=='service-selector'">
    <div class="modal-content">
        <div class="modal-header">
            <h3>{{goodSubTypeTranslationMap[selectedGoodSubType]}}</h3>
            <span (click)="closeModalWindow();" class="close">&times;</span>
        </div>
        <div class="modal-body">
            <app-good-filter [goods]="goods" [goodType]="selectedGoodType" [goodSubType]="selectedGoodSubType"
                (toggleValue)="insertCartElement($event, true)"></app-good-filter>
        </div>
    </div>
</div>

<div class="modal position-selector" *ngIf="modalOpened=='position-selector'">
    <div class="modal-content  modal-content-narrow">
        <div class="modal-header">
            <h3>Pozíció kiválasztása</h3>
            <span (click)="closeModalWindow();" class="close">&times;</span>
        </div>
        <div class="modal-body modal-auto-height">
            <app-wheel-selector (toggleValue)="setPendingCartItemPosition($event)"></app-wheel-selector>
            <button class="btn btn-primary" (click)="appendToCart(pendingCartItem)"><i class="fa fa-plus"></i> Hozzáadás
                a kosárhoz</button>
        </div>
    </div>
</div>

<div class="modal position-selector" *ngIf="modalOpened=='cart-item-editor' && cartItemToModify">
    <div class="modal-content modal-content-narrow">
        <div class="modal-header">
            <h3>Tétel módosítása</h3>
            <span (click)="closeModalWindow();" class="close">&times;</span>
        </div>
        <div class="modal-body modal-auto-height">
            <label for="cart-item-quantity">Mennyiség</label>
            <input type="number" name="cart-item-quantity" [(ngModel)]="cartItemToModifyForm.quantity">
            <label for="cart-item-quantity">Ár (bruttó)</label>
            <input type="number" name="cart-item-price" [(ngModel)]="cartItemToModifyForm.price">
            <button class="btn btn-primary" (click)="saveCartItemModifications()"><i class="fa fa-save"></i>
                Mentés</button>
        </div>
    </div>
</div>

<div class="modal plate-selector" *ngIf="modalOpened=='plate-selector'">
    <div class="modal-content">
        <div class="modal-header">
            <h3>Beolvasás</h3>
            <span (click)="closeModalWindow();" class="close">&times;</span>
        </div>
        <div class="modal-body modal-auto-height">
            <app-license-plate (toggleValue)="setLicensePlate($event)"></app-license-plate>
        </div>
    </div>
</div>