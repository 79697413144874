import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class MockInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const { enabled } = environment.api.mock;

    if (enabled) {

      const requestUri = `${request.url.replace(
        environment.api.hostname,
        environment.api.mock.hostname
      )}.json`;

      const interceptedRequest = request.clone({
        url: requestUri,
        method: 'GET',
      });

      return next.handle(interceptedRequest);
    }

    return next.handle(request);
  }
}
