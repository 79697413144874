export enum Displays {
    FRONT_1 = 'FRONT_1',
    FRONT_2 = 'FRONT_2',
    INNER_1 = 'INNER_1',
    INNER_2 = 'INNER_2',
    INNER_3 = 'INNER_3',
    INNER_4 = 'INNER_4',
    OFFICE_1 = 'OFFICE_1',
    OFFICE_2 = 'OFFICE_2',
}

export const DisplayNames: Record<string, string> = {
    [Displays.FRONT_1]: "Külső 1",
    [Displays.FRONT_2]: "Külső 2",
    [Displays.INNER_1]: "Belső 1",
    [Displays.INNER_2]: "Belső 2",
    [Displays.INNER_3]: "Belső 3",
    [Displays.INNER_4]: "Belső 4",
    [Displays.OFFICE_1]: "Iroda 1",
    [Displays.OFFICE_2]: "Iroda 2",
}