import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { User, UserClass } from "../../interfaces/user.interface";
import { AuthService } from "../../services/auth.service";
import { UserService } from "../../services/user.service";
import { MessageService } from "src/app/services/message/message.service";

const SUCCESS_MESSAGE = "Módosítások tárolva!";

@Component({
  selector: "app-user-profile",
  templateUrl: "./user-profile.component.html",
  styleUrls: ["./user-profile.component.less"],
})
export class UserProfileComponent implements OnInit {
  public user: User = new UserClass();

  public detailsForm = new FormGroup({
    email: new FormControl("", [Validators.required]),
    name: new FormControl("", [Validators.required]),
  });

  public passwordForm = new FormGroup({
    password: new FormControl("", [Validators.required]),
    passwordRepeat: new FormControl("", [Validators.required]),
  });

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private readonly messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.userService.getUserProfile().subscribe({
      next: (user: User) => {
        this.user = user;
        this.detailsForm.setValue({
          email: this.user.email,
          name: this.user.name,
        });
      },
      error: (e) => {
        console.error(e);
      },
    });
  }

  updateDetails(): void {
    const user = JSON.parse(JSON.stringify(this.user));

    user.name = this.detailsForm.get("name")?.value;
    user.email = this.detailsForm.get("email")?.value;

    delete user.password;
    delete user.passwordRepeat;

    this.userService.setUser(user).subscribe({
      next: () => {
        this.authService.setAuthenticatedUser(user);
        this.messageService.createMessage(SUCCESS_MESSAGE,'success');
      },
      error: (error) => {
        this.messageService.createMessage(error.error.message, "error");
      },
    });
  }

  updatePassword(): void {
    const user = JSON.parse(JSON.stringify(this.user));

    user.password = this.passwordForm.get("password")?.value;
    user.passwordRepeat = this.passwordForm.get("passwordRepeat")?.value;

    this.userService.setUser(user).subscribe({
      next: () => {
        this.messageService.createMessage(SUCCESS_MESSAGE,'success');
      },
      error: (error) => {
        this.passwordForm.setValue({ password: "", passwordRepeat: "" });
        this.messageService.createMessage(error.error.message, "error");
      },
    });
  }
}
