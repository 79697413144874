import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

export interface City {
    id: number;
    zipcode: string;
    city: string;
}

export interface Street {
    id: number;
    zipcode: string;
    city: string;
    street: string;
}

@Injectable({
    providedIn: "root",
})
export class ZipcodeService {
    constructor(private readonly http: HttpClient) { }

    public findCity(zipcode: string): Observable<City | null> {
        return this.http.post<City>(`${environment.services.zipcode}/city/search`, {
            zipcode
        });
    }

    public findStreets(zipcode: string, city: string): Observable<Street[]> {
        return this.http.post<Street[]>(`${environment.services.zipcode}/street/search`, {
            zipcode, city
        });
    }
}
