<div class="container">
    <div class="row">
        <div class="column">
            <h2 class="text-center">Hello <b>{{user.name}}</b></h2>
            <hr>
        </div>
    </div>
    <div class="row">
        <div class="column">
            <form [formGroup]="detailsForm" (ngSubmit)="updateDetails()">
                <div class="column">
                    <div>
                        <h4>Általános adatok</h4>
                    </div>

                    <div>
                        <label for="name">Név</label>
                        <input formControlName="name" type="text" name="name" id="name" placeholder="Név...">
                    </div>
                    <div>
                        <label for="email">E-mail cím</label>
                        <input formControlName="email" type="email" name="email" id="email" placeholder="Email...">
                    </div>
                    <div>
                        <button type="submit" class="button">Változtatások mentése</button>
                    </div>
                </div>
            </form>
        </div>
        <div class="column">
            <form [formGroup]="passwordForm" (ngSubmit)="updatePassword()">
                <div class="column">
                    <div>
                        <h4>Jelszó megváltoztatása</h4>
                    </div>

                    <div>
                        <label for="password">Új jelszó</label>
                        <input formControlName="password" type="password" name="password-1" id="password-1"
                            placeholder="Új jelszó..." autocomplete="new-password">
                    </div>
                    <div>
                        <label for="passwordRepeat">Új jelszó ismétlés</label>
                        <input formControlName="passwordRepeat" type="password" name="password-2" id="password-2"
                            placeholder="Új jelszó ismétlés..." autocomplete="new-password">
                    </div>
                    <div>
                        <button type="submit" class="button">Új jelszó használata</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>