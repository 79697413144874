import { AfterContentChecked, AfterContentInit, AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-vehicle-age',
  templateUrl: './vehicle-age.component.html',
  styleUrls: ['./vehicle-age.component.less']
})
export class VehicleAgeComponent implements AfterContentChecked {

  @Input() age: string | undefined;
  @Output() toggleValue: EventEmitter<string> = new EventEmitter();

  public selectedValue: string | undefined;

  public setSelectedValue(value: string): void {
    this.selectedValue = value;
    this.exposeSelectedValue();
  }

  public exposeSelectedValue(): void {
    this.toggleValue.emit(this.selectedValue);
  }

  ngAfterContentChecked(): void {
    this.selectedValue = this.age;
  }
}
