import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { ISession, SessionToPresent } from "./session.interface";

interface SessionSearchDto {
  where: {
    [k: string]: string;
  },
  limit?: number;
  offset?: number;
}

@Injectable({
  providedIn: "root",
})
export class SessionService {
  constructor(private readonly http: HttpClient) { }

  //@todo: move this to separated service and make it configurable
  public getCompanyByTaxnumber(taxnumber: string): Observable<any> {
    return this.http.get(`https://nettogumi.hu/nav/validate?taxnumber=${taxnumber}`);
  }

  public searchSession(dto: SessionSearchDto): Observable<ISession[]> {
    return this.http.post<ISession[]>(
      `${environment.api.hostname}/session/search`, dto
    );
  }

  public setSessionToPresent(dto: SessionToPresent) {
    return this.http.post(`${environment.api.hostname}/session/session-to-present`, dto);
  }

  public getSessionToPresent() {
    return this.http.get<SessionToPresent>(`${environment.api.hostname}/session/session-to-present`);
  }

  public deleteSessionToPresent() {
    return this.http.delete(`${environment.api.hostname}/session/session-to-present`);
  }

  public getSessions(): Observable<ISession[]> {
    return this.http.get<ISession[]>(`${environment.api.hostname}/session`);
  }

  public createSession(dataset: any): Observable<ISession> {
    return this.http.post<ISession>(
      `${environment.api.hostname}/session`,
      dataset
    );
  }
  public updateSession(session: any): Observable<ISession> {
    return this.http.patch<ISession>(
      `${environment.api.hostname}/session/update/${session.id}`,
      session
    );
  }

  public getSession(id: number): Observable<ISession> {
    return this.http.get<ISession>(`${environment.api.hostname}/session/${id}`);
  }

  public deleteSession(id: number) {
    return this.http.delete(`${environment.api.hostname}/session/${id}`);
  }

  public indicateGroupBilling(taxNumber: string) {
    return this.http.post<{ closed: number }>(`${environment.api.hostname}/session/group-billing`, { taxNumber });
  }
}
