import { common } from './common';

export const environment = {
  ...common,
  production: true,
  services: {
    zipcode: 'https://zipcode.terrex.hu',
    sessionDetailsPage:'https://details.autodrom.hu'
  },
  api: {
    hostname: 'https://api-uat.autodrom.hu',
    mock: { enabled: false, hostname: 'https://app-uat.autodrom.hu' },
  },
};
