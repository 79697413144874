import { Component, OnDestroy, OnInit } from "@angular/core";
import { SessionService } from "../../services/session.service";
import { ISession, SessionState, SessionType } from "../../services/session.interface";
import { Subscription, } from "rxjs";
import { MessageService } from "src/app/services/message/message.service";
import { Good, GoodType } from "src/app/modules/good/services/good.interface";
import { GoodService } from "src/app/modules/good/services/good.service";
import { TextMessageService } from "src/app/services/text-message/text-message.service";
import { ActivatedRoute } from "@angular/router";
import { BillingMethod } from "src/app/utils/billing-methods";
import { BillingFormat } from "src/app/utils/billing-formats";
import { environment } from "src/environments/environment";
import { PaymentMethod } from "src/app/utils/payment-methods";

const plateFilter = (session: ISession, term: string) =>
  session.plate &&
  term &&
  session.plate.toLowerCase().includes(term.toLowerCase());

const creationDateFilter = (session: ISession, term: string) =>
  session.createdAt &&
  term &&
  session.createdAt.toLowerCase().includes(term.toLowerCase());


const taxNumberFilter = (session: ISession, term: string) =>
  session.taxnumber &&
  term &&
  session.taxnumber.toLowerCase().includes(term.toLowerCase());

const phoneNumberFilter = (session: ISession, term: string) =>
  session.phone &&
  term &&
  session.phone.toLowerCase().includes(term.toLowerCase());

@Component({
  selector: "app-session-list",
  templateUrl: "./session-list.component.html",
  styleUrls: ["./session-list.component.less"],
})
export class SessionListComponent implements OnInit, OnDestroy {

  public list: { raw: ISession[], filtered: ISession[] } = { raw: [], filtered: [] }

  public filters: { type: SessionType | undefined, state: SessionState } = { type: SessionType.SERVICE, state: SessionState.ACTIVE };

  public sessionDataSubscription: Subscription = new Subscription();
  public goodDataSubscription: Subscription = new Subscription();

  public modalOpened: string = '';
  public selectedSessionId: number | undefined = 0;

  public services: Good[] = [];

  public billingMethods = BillingMethod;
  public billingFormats = BillingFormat;
  public paymentMethods = PaymentMethod;

  private escapeKeyHandler = this.escapeHandler.bind(this);

  constructor(
    private readonly sessionService: SessionService,
    private readonly messageService: MessageService,
    private readonly goodService: GoodService,
    private readonly textMessageService: TextMessageService,
    private readonly route: ActivatedRoute
  ) {

  }

  private escapeHandler(e: KeyboardEvent): void {
    if (e.key === "Escape") {
      this.closeModalWindow();
    }
  }

  public openModalWindow(id: string, sessionId: number | undefined): void {
    this.modalOpened = id;
    this.selectedSessionId = sessionId;
    document.addEventListener("keyup", this.escapeKeyHandler);
  }

  public closeModalWindow(): void {
    this.modalOpened = "";
    document.removeEventListener("keyup", this.escapeKeyHandler);
  }

  public async sendTextMessage(session: ISession): Promise<boolean> {
    return await this.textMessageService.sendDetailsPageMessage(session.hash, session.phone);
  }

  public filter(term?: string) {

    const { type, state } = this.filters;
    const elements = this.list.raw.filter((session: ISession) => session.sessionState === state && (type ? session.sessionType === type : true));

    if (!term) {
      this.list.filtered = elements;
      return;
    }

    this.list.filtered = elements.filter((session) => {
      return plateFilter(session, term) || creationDateFilter(session, term) || taxNumberFilter(session, term) || phoneNumberFilter(session, term);
    });
  }

  public deleteSession(sessionId: number | undefined): void {
    if (!sessionId) {
      return;
    }
    this.messageService
      .createConfirmation("Valóban törölni szeretné ezt a munkamenetet?")
      .then((confirmationResult) => {
        if (confirmationResult.isConfirmed) {
          this.sessionService.deleteSession(sessionId).subscribe({
            next: () => {
              this.loadSessions();
            },
            error: (e) =>
              this.messageService.createMessage(e.error.message, "error"),
          });
        }
      });
  }

  private prepareSession(session: ISession) {

    let maximumEffortInMinutes = 0;

    for (const index in session.products) {
      const product = session.products[index];
      const good = this.services.find((service: Good) => service.id === product.goodId);
      if (good) {
        maximumEffortInMinutes += (good.minutes * product.quantity || 0);
      }
    }

    session.maximumEffortInMinutes = maximumEffortInMinutes;
    return session;
  }

  private loadSessions() {
    this.sessionDataSubscription = this.sessionService.getSessions().subscribe({
      next: (sessions: ISession[]) => {
        this.list.raw = sessions.map((session: ISession) => this.prepareSession(session));
        this.list.filtered = this.list.raw;
        this.filter();
      },
      error: (e) => console.error(e),
    });
  }

  /**
   * Make
   */
  public setSessionAsBillable(sessionId: number | undefined): void {
    if (!sessionId) { return; }
    this.sessionService.updateSession({ id: sessionId, readyToSymbol: true }).subscribe({
      next: () => this.messageService.createMessage('A munkamenet megjelölve számlázhatóként! Néhány percen belül meg fog jelenni a Symbol alkalmazásban', 'success'),
      error: (e) => this.messageService.createMessage(e.message, 'error')
    })
  }

  ngOnInit(): void {

    // load all services
    this.goodDataSubscription = this.goodService.getGoods(GoodType.SERVICE).subscribe({
      next: (goods: Good[]) => {
        this.services = goods;
      },
      error: (e) => console.error(e)
    });

    // use promise to get initial data without emiter
    this.loadSessions();

    this.route.queryParams.subscribe(params => {

      const { filter } = params;

      // reset when not default was applied
      if (!filter) {
        this.filters.type = SessionType.SERVICE;
        this.filters.state = SessionState.ACTIVE;
        this.filter();
      }

      if (filter) {
        switch (filter) {
          case 'history':
            this.filters.type = undefined;
            this.filters.state = SessionState.CLOSED;
            this.filter();
            break;
          case 'scheduled':
            this.filters.type = undefined;
            this.filters.state = SessionState.SCHEDULED;
            this.filter();
            break;
          default:
            this.filters.type = undefined;
            this.filters.state = SessionState.ACTIVE;
            this.filter();
            break;
        }
      }
    });
  }

  public closeSession(sessionId: number | undefined) {
    if (!sessionId) { return; }

    this.messageService
      .createConfirmation("Valóban megérkezett az átutalás és szeretné lezárni a munkamenetet?")
      .then((confirmationResult) => {
        if (confirmationResult.isConfirmed) {
          this.sessionService.updateSession({ id: sessionId, sessionState: SessionState.CLOSED }).subscribe({
            next: () => { this.messageService.createMessage('A munkamenet lezárva!', 'success'); this.loadSessions() },
            error: (e) => this.messageService.createMessage(e.message, 'error')
          });
        }
      });
  }

  ngOnDestroy(): void {
    this.sessionDataSubscription.unsubscribe();
    this.goodDataSubscription.unsubscribe();
  }
}
