import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { HttpClientModule } from "@angular/common/http";

import { UserService } from "./services/user.service";
import { UserLoginComponent } from "./components/user-login/user-login.component";
import { UserProfileComponent } from "./components/user-profile/user-profile.component";
import { UserPasswordForgotComponent } from "./components/user-password-forgot/user-password-forgot.component";
import { UserPasswordResetComponent } from "./components/user-password-reset/user-password-reset.component";
import { UserLogoutComponent } from "./components/user-logout/user-logout.component";
import { UserListComponent } from "./components/user-list/user-list.component";
import { UserCreateComponent } from "./components/user-create/user-create.component";
import { UserEditComponent } from "./components/user-edit/user-edit.component";

@NgModule({
  declarations: [
    UserLoginComponent,
    UserProfileComponent,
    UserPasswordForgotComponent,
    UserPasswordResetComponent,
    UserLogoutComponent,
    UserListComponent,
    UserCreateComponent,
    UserEditComponent,
  ],
  providers: [UserService],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
  ]
})
export class UserModule {}
