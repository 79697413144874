import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { ContentService } from '../../../services/content.service';
import { getWorkstationId } from 'src/app/utils/identify-machine';
import { IContent } from '../../../services/content.interface';
import { DisplayService, DisplaySetting } from 'src/app/services/display/display.service';

interface ContentElementToPresent {
  resource: string;
  timeout: number;
}

@Component({
  selector: 'app-present-static',
  templateUrl: './present-static.component.html',
  styleUrls: ['./present-static.component.less']
})
export class PresentStaticComponent implements OnDestroy, AfterViewInit {
  @ViewChild("frame") frame:
    | ElementRef<HTMLIFrameElement>
    | undefined;

  constructor(private readonly contentService: ContentService, private readonly displayService: DisplayService) { }

  public items: Array<ContentElementToPresent> | undefined;

  public currentElementIndex: number = -1;

  public displayId: string | null = getWorkstationId();

  public displaySequenceInterval: any = undefined;
  public loadingSequenceInterval: any = undefined;
  public loadingSequenceDuration: number = 60 * 1000;

  public defaultSize = { width: 1280, height: 730 };
  public displaySettings: { width: number, height: number } = this.defaultSize;

  public defaultContent: ContentElementToPresent = { resource: "", timeout: 10 };

  ngOnDestroy(): void {
    this.resetDisplaySequence();
    this.resetLoadingSequence();
  }

  public getDisplaySettings() {
    this.displayService.getDisplaySettings().subscribe({
      next: (settings: Array<DisplaySetting>) => {
        const stored = settings.find((setting: DisplaySetting) => setting.displayId === this.displayId);
        if (stored) {
          this.displaySettings.width = stored.width;
          this.displaySettings.height = stored.height;
        }
      }
    })
  }

  public setContent(resource: string) {
    if (!this.frame) { return; }
    this.frame.nativeElement.src = resource;
  }

  public getNextContent(): ContentElementToPresent | null {

    if (this.items) {
      this.currentElementIndex = this.currentElementIndex === this.items.length ? 0 : this.currentElementIndex + 1;
      return this.items[this.currentElementIndex];
    }

    return null;
  }

  ngAfterViewInit(): void {
    this.resetDisplaySequence();
    this.resetLoadingSequence();
    this.getContent();
    this.getDisplaySettings();
  }

  public resetDisplaySequence(): void {
    clearInterval(this.displaySequenceInterval);
  }

  public resetLoadingSequence(): void {
    clearInterval(this.loadingSequenceInterval);
  }

  public resetCurrentElementIndex(): void {
    this.currentElementIndex = -1;
  }

  public display(): void {

    const nextItem = this.getNextContent();
    if (!nextItem) { return; }

    this.setContent(nextItem.resource);

    // avoid running with interval if there is only one element in the list
    // until this component gets new list from server via SSE connection
    if (this.items?.length === 1) { return; }

    const timeout = (Number(nextItem.timeout) || 10) * 1000;

    this.resetDisplaySequence();

    this.displaySequenceInterval = setInterval(() => {
      this.display();
    }, timeout);
  }

  /**
   * Compare content set
   * If stringified format matching we can consider there is no change
   */
  public isSameContent(items: ContentElementToPresent[]): boolean {
    return JSON.stringify(items) === JSON.stringify(this.items);
  }

  public getContent(): void {

    const displayId = getWorkstationId();

    if (!displayId) { return; }

    this.contentService.findContent({ displayId: displayId, visible: true }).subscribe({

      next: (contents: IContent[]) => {

        let items = contents.map((content: IContent) => { return { resource: content.resource, timeout: content.keepFor } });

        // use default content when no content was assigned to this display
        if (items.length === 0) {
          items = [this.defaultContent];
        }

        if (!this.isSameContent(items)) {

          this.items = items;

          this.resetCurrentElementIndex();

          this.resetDisplaySequence();

          this.display();

          this.resetLoadingSequence();

          // fetch contents if every minute to get fresh and aligned with static content settings
          this.loadingSequenceInterval = setInterval(() => {
            this.getContent();
          }, this.loadingSequenceDuration);

        }

      },
      error: (e) => console.error(e)
    });
  }

}
