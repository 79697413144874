<div class="modal" *ngIf="modalState === 'OPEN' && settingsForm && modalSelected === modalWindows.DISPLAY_SETTINGS">
    <div class="modal-content modal-content-small">
        <div class="modal-header">
            <h3>Kijelző felbontás beállításai</h3>
            <span (click)="toggleDisplaySettingsModalWindow();" class="close">&times;</span>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="column">
                    <h4 class="display-name" style="display:inline;text-align: center;">{{getDisplayName(displaySelected)}}</h4>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <label for="width">Szélesség (w)</label>
                    <input type="number" name="width" id="width"  [(ngModel)]="settingsForm.width">
                </div>
                <div class="column">
                    <label for="width">Magasság (h)</label>
                    <input type="number" name="height" id="height"  [(ngModel)]="settingsForm.height">
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <button class="btn btn-primary" (click)="saveDisplaySettings()">Beállítások mentése</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" *ngIf="modalState === 'OPEN' && creationForm && modalSelected === modalWindows.CREATION">
    <div class="modal-content modal-content-small">
        <div class="modal-header">
            <h3>Hivatkozás hozzáadása</h3>
            <span (click)="toggleCreationModalWindow();" class="close">&times;</span>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="column">
                    <label for="">Hivatkozás</label>
                    <textarea cols="30" rows="10" resize="false" id="resourceUrlInput"
                        [(ngModel)]="creationForm.resource"></textarea>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <label for="">Megjelenhet ezekben az órákban</label>
                    <table style="max-width: 500px;">
                        <tr>
                            <td>Ettől</td>
                            <td>Eddig</td>
                        </tr>
                        <tr>
                            <td><input type="time" [(ngModel)]="creationForm.intervalStarts"></td>
                            <td><input type="time" [(ngModel)]="creationForm.intervalEnds"></td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <label for="active-in-second">Aktív (mp)</label>
                    <input type="text" name="active-in-seconds" [(ngModel)]="creationForm.keepFor">
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <label for="">Aktív napok</label>
                    <table class="day-list-table">
                        <tr>
                            <td width="100">
                                <label for="monday">Hétfő</label>
                            </td>
                            <td><input type="checkbox" name="monday" id="monday"
                                    [(ngModel)]="creationForm.selectedDays['MONDAY']"></td>
                        </tr>
                        <tr>
                            <td>
                                <label for="tuesday">Kedd</label>
                            </td>
                            <td><input type="checkbox" name="tuesday" id="tuesday"
                                    [(ngModel)]="creationForm.selectedDays['TUESDAY']"></td>
                        </tr>
                        <tr>
                            <td>
                                <label for="wednesday">Szerda</label>
                            </td>
                            <td><input type="checkbox" name="wednesday" id="wednesday"
                                    [(ngModel)]="creationForm.selectedDays['WEDNESDAY']"></td>
                        </tr>
                        <tr>
                            <td>
                                <label for="thursday">Csütörtök</label>
                            </td>
                            <td><input type="checkbox" name="thursday" id="thursday"
                                    [(ngModel)]="creationForm.selectedDays['THURSDAY']"></td>
                        </tr>
                        <tr>
                            <td>
                                <label for="friday">Péntek</label>
                            </td>
                            <td><input type="checkbox" name="friday" id="friday"
                                    [(ngModel)]="creationForm.selectedDays['FRIDAY']"></td>
                        </tr>
                        <tr>
                            <td>
                                <label for="saturday">Szombat</label>
                            </td>
                            <td><input type="checkbox" name="saturday" id="saturday"
                                    [(ngModel)]="creationForm.selectedDays['SATURDAY']"></td>
                        </tr>
                        <tr>
                            <td>
                                <label for="sunday">Vasárnap</label>
                            </td>
                            <td><input type="checkbox" name="sunday" id="sunday"
                                    [(ngModel)]="creationForm.selectedDays['SUNDAY']"></td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="column"><button class="btn" (click)="addResource()">Hivatkozás hozzáadása</button></div>
            </div>
        </div>
    </div>
</div>


<div class="container">
    <div class="row">
        <div class="column">
            <table *ngFor="let display of displays;">
                <thead>
                    <tr>
                        <th colspan="2"><span style="display:flex;justify-content: space-between;"><span
                                    class="display-name">{{getDisplayName(display)}}</span> <span><a
                                        (click)="toggleDisplaySettingsModalWindow(display)" style="cursor: pointer;"><i
                                            class="fa fa-cog"></i></a></span></span></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <a (click)="toggleCreationModalWindow(display);" class="add-resource-btn"><i
                                    class="fa fa-plus"></i>&nbsp;Tartalom hozzáadása</a>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <table>
                                <thead>
                                    <tr>
                                        <th>URL</th>
                                        <th width="170">Intervallum kezdete</th>
                                        <th width="170">Intervallum vége</th>
                                        <th width="200">Napok</th>
                                        <th width="100">Aktív (mp)</th>
                                        <th width="100">Művelet</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of getResourceList(display)">
                                        <td class="resource-content">{{item.resource}}</td>
                                        <td>{{item.intervalStarts}}</td>
                                        <td>{{item.intervalEnds}}</td>
                                        <td>{{item.days}}</td>
                                        <td>{{item.keepFor}}</td>
                                        <td><a class="remove-resource-btn" (click)="removeResource(item.id)">Törlés</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>