import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MessageService } from "src/app/services/message/message.service";
import { GoodService } from "../../services/good.service";
import { Good, GoodSubType, GoodType } from "../../services/good.interface";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";

@Component({
  selector: "app-good-create",
  templateUrl: "./good-create.component.html",
  styleUrls: ["./good-create.component.less"],
})
export class GoodCreateComponent implements OnInit {

  public goodServiceSubscription: Subscription = new Subscription();

  public good: Good | undefined;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly messageService: MessageService,
    private readonly goodService: GoodService,
    private readonly route: ActivatedRoute
  ) { }

  public form: FormGroup | undefined = this.formBuilder.group({
    id: [0, Validators.required],
    title: ["", Validators.required],
    description: [""],
    sku: ["", Validators.required],
    barcode: ["", Validators.required],
    price: [0, Validators.required],
    vat: [0, Validators.required],
    discount: [0, Validators.required],
    stock: [0, Validators.required],
    type: ["", Validators.required],
    subType: ["", Validators.required],
    minutes: [0],
    color: ["#000000"],
    isPack: [false, Validators.required],
    enabled: [true, Validators.required],
    category: [""]
  });

  public save() {

    if (this.form?.invalid) {
      this.messageService.createMessage(
        "Minden csillaggal megjelölt mező kitöltése kötelező",
        "error"
      );
      return;
    }

    const formValues = this.form?.value;

    const good: Good = {
      id: formValues.id,
      title: formValues.title as string,
      description: formValues.description as string,
      sku: formValues.sku as string,
      barcode: formValues.barcode as string,
      price: formValues.price as number,
      vat: formValues.vat as number,
      discount: formValues.discount as number,
      type: formValues.type as GoodType,
      enabled: formValues.enabled,
      stock: formValues.stock as number,
      subType: formValues.subType as GoodSubType,
      color: formValues.color as string,
      minutes: formValues.minutes as number,
      isPack: formValues.isPack as boolean,
      category: formValues.category as string
    };

    if (good.id > 0) {
      this.goodService.updateGood(good, good.id).subscribe({
        next: () => {
          this.messageService.createMessage(
            "Módosítások mentése sikeres",
            "success"
          );
        },
        error: (e) => {
          this.messageService.createMessage(e.error.message, "error");
        },
      });
    } else {
      this.goodService.createGood(good).subscribe({
        next: () => {
          this.messageService.createMessage(
            "Módosítások mentése sikeres",
            "success"
          );
        },
        error: (e) => {
          this.messageService.createMessage(e.error.message, "error");
        },
      });
    }



  }

  public fetchGood(id: number) {
    if (this.goodServiceSubscription) {
      this.goodServiceSubscription.unsubscribe();
    }
    this.goodServiceSubscription = this.goodService.getGood(id).subscribe({
      next: (good: Good) => {
        this.good = good;
        this.form = this.formBuilder.group({
          id: [good.id, Validators.required],
          title: [good.title, Validators.required],
          description: [good.description],
          sku: [good.sku, Validators.required],
          barcode: [good.barcode, Validators.required],
          price: [good.price, Validators.required],
          vat: [good.vat, Validators.required],
          discount: [good.discount, Validators.required],
          stock: [good.stock, Validators.required],
          type: [good.type, Validators.required],
          enabled: [good.enabled, Validators.required],
          subType: [good.subType, Validators.required],
          color: [good.color],
          minutes: [good.minutes],
          isPack: [good.isPack as boolean, Validators.required],
          category: [good.category as string]
        });
      },
      error: (e) => this.messageService.createMessage(e.error.message, "error"),
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe({
      next: (params: any) => {
        if(params.id) {this.fetchGood(params.id);}
      },
      error: (e) => console.error(e),
    });
  }
}
