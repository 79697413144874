import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../../environments/environment";
import { User, UserLink } from "../interfaces/user.interface";

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(private http: HttpClient) {}

  validatePasswordResetHash(hash: string | null): Observable<UserLink> {
    return this.http.get<UserLink>(
      `${environment.api.hostname}/user/password-reset-validate/${hash}`
    );
  }

  resetPassword(dto: any) {
    return this.http.post<User>(
      `${environment.api.hostname}/user/password-reset`,
      dto
    );
  }

  forgotPassword(email: string) {
    return this.http.post(
      `${environment.api.hostname}/user/password-forgot`,
      {
        email: email,
      }
    );
  }

  createUser(dto: any) {
    return this.http.post<User>(`${environment.api.hostname}/user/create`, dto);
  }

  getUser(id: number): Observable<User> {
    return this.http.get<User>(`${environment.api.hostname}/user/${id}`);
  }

  getUserProfile(): Observable<User> {
    return this.http.get<User>(`${environment.api.hostname}/user/profile`);
  }

  setUser(user: User): Observable<User> {
    return this.http.put<User>(`${environment.api.hostname}/user/update`, user);
  }

  getUsers(): Observable<User[]> {
    return this.http.get<User[]>(`${environment.api.hostname}/user/list`);
  }

  deleteUser(id: number) {
    return this.http.delete(`${environment.api.hostname}/user/${id}`);
  }
}
