<div class="vehicle-age-selector">
    <button (click)="setSelectedValue('new')" class="action-button"
        [ngClass]="{'active':selectedValue === 'new'}">Új</button>
    <button (click)="setSelectedValue('2-5')" class="action-button"
        [ngClass]="{'active':selectedValue === '2-5'}">2-5</button>
    <button (click)="setSelectedValue('6-10')" class="action-button"
        [ngClass]="{'active':selectedValue === '6-10'}">6-10</button>
    <button (click)="setSelectedValue('11-15')" class="action-button"
        [ngClass]="{'active':selectedValue === '11-15'}">11-15</button>
    <button (click)="setSelectedValue('15+')" class="action-button"
        [ngClass]="{'active':selectedValue === '15+'}">15+</button>
</div>