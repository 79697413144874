import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CameraService } from 'src/app/services/camera/camera.service';
import { MessageService } from 'src/app/services/message/message.service';

@Component({
  selector: 'app-license-plate',
  templateUrl: './license-plate.component.html',
  styleUrls: ['./license-plate.component.less']
})
export class LicensePlateComponent implements OnInit {

  @Output() toggleValue: EventEmitter<string> = new EventEmitter();

  public plates: string[] = [];

  constructor(private readonly cameraService: CameraService, private readonly messageService: MessageService) { }

  private readLicensePlates(): void {
    this.cameraService.getLicensePlateList().subscribe({
      next: (list: string[]) => { this.plates = list; },
      error: (e) => this.messageService.createMessage(e.error.error, 'error')
    });
  }

  public publish(value: string): void {
    this.toggleValue.emit(value);
  }

  ngOnInit(): void {
    this.readLicensePlates();
  }
}
