import { Routes } from "@angular/router";
import { GoodListComponent } from "./components/good-list/good-list.component";
import { GoodCreateComponent } from "./components/good-create/good-create.component";

export const getGoodModuleRoutes = (ActivationGuard: any): Routes => {
  return [
    {
      path: "good/list",
      component: GoodListComponent,
      canActivate: [ActivationGuard],
      data: { showNavigation: true, componentTitle: "Termékek - szolgáltatások listája" },
    },
    {
      path: "good/manage",
      component: GoodCreateComponent,
      canActivate: [ActivationGuard],
      data: { showNavigation: true, componentTitle: "Termék - szolgáltatás kezelése" },
    },
    {
      path: "good/manage/:id",
      component: GoodCreateComponent,
      canActivate: [ActivationGuard],
      data: { showNavigation: true, componentTitle: "Termék - szolgáltatás kezelése" },
    }
  ];
};
