import { Injectable } from '@angular/core';
import { Cart, ICart } from './cart';
import { CartPersistence } from './cart-persistence';
import { CartItem, ICartItem } from './cart-item';

export interface ICartService {
  createCart: (id: string) => ICart;
  removeCart: (id: string) => void;
  storeCart: (id: string, cart: ICart) => void;
}

@Injectable({
  providedIn: 'root',
})
export class CartService implements ICartService {
  public createCart(id: string): ICart {
    const stored = CartPersistence.getItem(id);

    if (stored) {
      const { elements } = JSON.parse(stored);

      const elementInstances = elements.map(
        (item: ICartItem) => new CartItem(item)
      );

      return new Cart(id, elementInstances);
    }

    const cart = new Cart(id);
    this.storeCart(id, cart);

    return cart;
  }

  public storeCart(id: string, cart: ICart): void {
    CartPersistence.setItem(id, cart);
  }

  public removeCart(id: string): void {
    CartPersistence.removeItem(id);
  }
}
