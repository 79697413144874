import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import {
  User,
  UserAuthenticated,
  UserRoles,
} from "../interfaces/user.interface";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(private http: HttpClient) {}

  private isAuthenticatedObservable = new BehaviorSubject(false);

  getIsAuthenticatedObservable(): Observable<boolean> {
    return this.isAuthenticatedObservable as Observable<boolean>;
  }

  setIsAuthenticatedObservable() {
    this.isAuthenticatedObservable.next(!!this.getAccessToken());
  }

  authenticate(
    email: string | null | undefined,
    password: string | null | undefined
  ) {
    return this.http.post(`${environment.api.hostname}/authenticate/login`, {
      email,
      password,
    });
  }

  isSuperUser(user: User): boolean {
    return user?.role === UserRoles.SONGOKU;
  }

  isAdminUser(user: User) {
    return user?.role === UserRoles.ADMIN;
  }

  destroySession() {

    // cleanup localstorage
    window.localStorage.removeItem('user');
    window.localStorage.removeItem('access_token');

    this.setIsAuthenticatedObservable();
  }

  setAccessToken(token: string) {
    window.localStorage.setItem("access_token", token);
    this.setIsAuthenticatedObservable();
  }

  getAccessToken(): string | null {
    return window.localStorage.getItem("access_token");
  }

  setAuthenticatedUser(user: User | null): void {
    window.localStorage.setItem("user", JSON.stringify(user));
  }

  getAuthenticatedUser(): UserAuthenticated | null {
    const user = window.localStorage.getItem("user");
    if (!user) {
      return null;
    }
    return JSON.parse(user) as UserAuthenticated;
  }
}
