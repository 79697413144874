import { Component, OnDestroy, OnInit } from '@angular/core';
import { SessionService } from '../../services/session.service';
import { ISession } from '../../services/session.interface';

const DATA_LOADING_INTERVAL_TIMEOUT = 3000;

@Component({
  selector: 'app-session-details',
  templateUrl: './session-details.component.html',
  styleUrls: ['./session-details.component.less'],
})
export class SessionDetailsComponent implements OnInit, OnDestroy {

  public session: ISession | undefined = undefined;
  public activeState: string | undefined;
  public dataLoadingIntervalHandler: any = undefined;

  constructor(private readonly sessionService: SessionService) { }

  public ngOnDestroy(): void {
    clearInterval(this.dataLoadingIntervalHandler);
  }

  public reset(): void {
    this.session = undefined;
    this.activeState = undefined;
  }

  public getSessionToPresent(): void {
    this.sessionService.getSessionToPresent().subscribe({
      next: (session: { sessionId: number, section: string }) => {
        if (!session) {
          this.reset();
          return;
        }

        if (this.session?.id !== session.sessionId) {
          this.setSession(session)
        }
      },
      error: (e) => console.error(e)
    })
  }

  public setSession({ sessionId, section }: { sessionId: number, section: string }): void {
    this.activeState = section;
    this.sessionService.getSession(sessionId).subscribe({
      next: (session: ISession) => {
        if (session) {
          this.session = session;
        }
      },
      error: (e) => { console.error(e); this.reset(); }
    });

  }

  public ngOnInit(): void {
    this.getSessionToPresent();
    this.dataLoadingIntervalHandler = setInterval(() => this.getSessionToPresent(), DATA_LOADING_INTERVAL_TIMEOUT);
  }

}
