import { Component } from "@angular/core";

@Component({
  selector: "app-session-dashboard",
  templateUrl: "./session-dashboard.component.html",
  styleUrls: ["./session-dashboard.component.less"],
})
export class SessionDashboardComponent {
  
}
