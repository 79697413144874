<div class="container">
    <div class="row">
        <div class="column">
            <div>
                <div class="input-group-prepend">
                    <a class="button" [routerLink]="['/good/manage']">Új termék létrehozása</a>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="column">
            <div><input type="text" #filterInput placeholder="Keresés..."></div>
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Cikkszám</th>
                        <th>Vonalkód</th>
                        <th>Megnevezés</th>
                        <th>Típus</th>
                        <th>Csomag</th>
                        <th>Nettó egységár</th>
                        <th>Áfa (%)</th>
                        <th>Kedv. (%)</th>
                        <th>Művelet</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let good of list.filtered">
                        <td>{{good.id}}</td>
                        <td>{{good.sku}}</td>
                        <td>{{good.barcode}}</td>
                        <td>{{good.title}}</td>
                        <td>{{good.type | goodType}}</td>
                        <td>{{good.isPack ? 'Igen': '-'}}</td>
                        <td>{{good.price | number}}</td>
                        <td>{{good.vat}} %</td>
                        <td>{{good.discount}} % </td>
                        <td>
                            <a [routerLink]="['/good/manage',good.id]">Szerkesztés</a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>