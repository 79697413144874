import { Pipe, PipeTransform } from "@angular/core";
import { GoodType } from "../../good/services/good.interface";

@Pipe({ name: "goodType" })
export class GoodTypePipe implements PipeTransform {
    transform(value: string): string {
        if (value === GoodType.SERVICE) {
            return "Szolgáltatás";
        }
        return "Termék";
    }
}