import { Routes } from "@angular/router";
import { ContentPresenterComponent } from "./components/content-presenter/content-presenter.component";
import { ContentManagerComponent } from "./components/content-manager/content-manager.component";

export const getContentModuleRoutes = (ActivationGuard: any): Routes => {
    return [
        {
            path: "content/present",
            component: ContentPresenterComponent,
            canActivate: [ActivationGuard],
            data: { showNavigation: false, subType: 'presenter' },
        },
        {
            path: "content/list",
            component: ContentManagerComponent,
            canActivate: [ActivationGuard],
            data: { showNavigation: true, componentTitle: 'Tartalmak' },
        }
    ]
}