export enum BillingMethod {
    'BILL' = 'BILL',
    'RECEIPT' = 'RECEIPT',
    'BILL_COLLECTION' = 'BILL_COLLECTION'
}

export const BillingMethodMap: Record<BillingMethod, string> = {
    [BillingMethod.BILL]: 'Számla',
    [BillingMethod.RECEIPT]: 'Nyugta',
    [BillingMethod.BILL_COLLECTION]: 'Gyűtjő számla'
}