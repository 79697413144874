import { Injectable } from "@angular/core";
import Swal, { SweetAlertIcon } from "sweetalert2";

@Injectable({
  providedIn: "root",
})
export class MessageService {
  public createToast(message: string) {
    const toast = Swal.mixin({
      toast: true,
      position: "top-right",
      timer: 5000,
      timerProgressBar: true,
      html: message,
      showConfirmButton:false,
      showCancelButton:false,
      showCloseButton:false
    });
    toast.fire({
      icon: "info"
    });
  }

  public createConfirmation(
    text: string,
    icon: SweetAlertIcon = "info",
    deniable: boolean = false
  ) {
    return Swal.fire({
      html: text,
      icon,
      confirmButtonText: "Igen",
      showDenyButton: true,
      denyButtonText: "Nem",
    });
  }
  public createMessage(text: string, icon: SweetAlertIcon = "success") {
    let title = "Siker";
    switch (icon) {
      case "success":
        title = "Siker";
        break;
      case "error":
        title = "Hiba";
        break;
      case "info":
        title = "Információ";
        break;
      case "question":
        title = "Megerősítés";
        break;
      case "warning":
        title = "Figyelmeztetés";
        break;
      default:
    }

    Swal.fire({ title, icon, html: text });
  }
}
