export enum Days {
    MONDAY = 'MONDAY',
    TUESDAY = 'TUESDAY',
    WEDNESDAY = 'WEDNESDAY',
    THURSDAY = 'THURSDAY',
    FRIDAY = 'FRIDAY',
    SATURDAY = 'SATURDAY',
    SUNDAY = 'SUNDAY'
};

export const DaysShortMap: Record<Days, string> = {
    [Days.MONDAY]: 'H',
    [Days.TUESDAY]: 'K',
    [Days.WEDNESDAY]: 'SZ',
    [Days.THURSDAY]: 'CS',
    [Days.FRIDAY]: 'P',
    [Days.SATURDAY]: 'SZO',
    [Days.SUNDAY]: 'V'
};

export const DaysNumericMap: Record<Days, number> = {
    [Days.MONDAY]: 1,
    [Days.TUESDAY]: 2,
    [Days.WEDNESDAY]: 3,
    [Days.THURSDAY]: 4,
    [Days.FRIDAY]: 5,
    [Days.SATURDAY]: 6,
    [Days.SUNDAY]: 0
}