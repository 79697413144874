import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BillingMethod } from 'src/app/utils/billing-methods';

@Component({
  selector: 'app-billing-method',
  templateUrl: './billing-method.component.html',
  styleUrls: ['./billing-method.component.less']
})
export class BillingMethodComponent {

  public selectedValue: BillingMethod | undefined;

  public billingMethods = BillingMethod;

  @Output() toggleValue: EventEmitter<BillingMethod> = new EventEmitter();

  @Input()
  public set initialValue(value: BillingMethod) {
    this.selectedValue = value;
  }

  public selectMethod(method: BillingMethod): void {
    // avoid trigger exposing selected value without any change
    if (this.selectedValue === method) { return; }
    this.selectedValue = method;
    this.exposeSelectedValue();
  }

  public exposeSelectedValue(): void {
    this.toggleValue.emit(this.selectedValue);
  }

}
