import {
  Component,
  ElementRef,
  OnInit,
  Pipe,
  PipeTransform,
  ViewChild,
} from "@angular/core";
import { GoodService } from "../../services/good.service";
import { debounceTime, fromEvent } from "rxjs";
import { Good, GoodType } from "../../services/good.interface";

@Pipe({ name: "goodType" })
export class GoodTypePipe implements PipeTransform {
  transform(value: string): string {
    if (value === GoodType.SERVICE) {
      return "Szolgáltatás";
    }
    return "Termék";
  }
}

@Component({
  selector: "app-good-list",
  templateUrl: "./good-list.component.html",
  styleUrls: ["./good-list.component.less"],
})
export class GoodListComponent implements OnInit {

  @ViewChild("filterInput", { static: true }) filterInput: | ElementRef<HTMLInputElement>;

  public list: { raw: Good[], filtered: Good[] } = { raw: [], filtered: [] }

  constructor(private readonly goodService: GoodService) { }

  public setInputFieldFocus(input: ElementRef<HTMLInputElement> | undefined): void {
    setTimeout(() => {
      input?.nativeElement.focus();
    }, 100);
  }

  ngAfterViewInit(): void {
    fromEvent(this.filterInput.nativeElement, 'keyup').pipe(debounceTime(500)).subscribe({
      next: () => {
        this.filter(this.filterInput.nativeElement.value)
      }
    })
  }

  ngOnInit(): void {
    this.goodService.getGoods(GoodType.SERVICE).subscribe({
      next: (goods: Good[]) => {
        this.list.raw = goods;
        this.list.filtered = this.list.raw;
        this.setInputFieldFocus(this.filterInput);
      },
      error: (e) => console.error(e),
    });
  }

  public filter(keyword: string) {

    if (!keyword || keyword.length < 3) {
      this.list.filtered = this.list.raw;
      return;
    }

    keyword = keyword.toLowerCase();

    this.goodService.searchGood(GoodType.PRODUCT, keyword).subscribe({
      next: (goods: Good[]) => {
        this.list.filtered = goods;
      },
      error: (e) => console.error(e)
    })

  }
}
