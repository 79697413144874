import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UserService } from "../../services/user.service";
import { MessageService } from "src/app/services/message/message.service";

@Component({
  selector: "app-user-password-forgot",
  templateUrl: "./user-password-forgot.component.html",
  styleUrls: ["./user-password-forgot.component.less"],
})
export class UserPasswordForgotComponent implements OnInit {
  public form: FormGroup | undefined;
  constructor(
    private readonly userService: UserService,
    private readonly formBuilder: FormBuilder,
    private readonly messageService: MessageService
  ) {}

  public send() {
    if (!this.form || this.form.invalid) {
      return;
    }

    const { email } = this.form?.value;

    this.userService.forgotPassword(email).subscribe({
      next: () => {
        this.messageService.createMessage(
          "A jelszó helyreállításához szükséges linket elküldtük a megadott e-mail címre!"
        ,'success');
        this.form?.reset();
      },
      error: (e) => this.messageService.createMessage(e.error.message, "error"),
    });
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
    });
  }
}
