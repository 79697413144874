import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CameraSnapshot } from "./camera.interface";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CameraService {
  constructor(private readonly http: HttpClient) { }

  public getLicensePlateList(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.api.hostname}/camera/list`);
  }

  public listenCameraSnapshots(): EventSource {
    return new EventSource(`${environment.api.hostname}/camera/listen`, {});
  }

  public getCameraSnapshot(): Observable<CameraSnapshot> {
    return this.http.get<CameraSnapshot>(
      `${environment.api.hostname}/camera/snapshot`
    );
  }
}
