import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SessionService } from '../../../services/session.service';

interface PresenterItem {
  id: PresenterDisplaySections;
  title: string;
  sort: number;
};

enum TabDirection {
  NEXT = 1,
  PREV = -1
};

export enum PresenterDisplaySections {
  MEASUREMENTS = 'MEASUREMENTS',
  PHOTOS = 'PHOTOS',
  PRODUCTS = 'PRODUCTS',
  BILLING = 'BILLING',
  PRICE = 'PRICE'
};

@Component({
  selector: 'app-presenter',
  templateUrl: './presenter.component.html',
  styleUrls: ['./presenter.component.less']
})
export class PresenterComponent implements OnInit, OnDestroy {

  @Input() sessionId: number | undefined = 0;

  public activeTab: PresenterDisplaySections;

  private keyboardHandler = this.keyHandler.bind(this);

  //@todo: reenable these elements when functionality implemented
  public tabs: Array<PresenterItem> = [
    //{ id: PresenterDisplaySections.MEASUREMENTS, title: 'Mérési eredmények', sort: 0 },
    //{ id: PresenterDisplaySections.PHOTOS, title: 'Fényképek', sort: 1 },
    //{ id: PresenterDisplaySections.PRODUCTS, title: 'Termékek / Szolgáltatások', sort: 2 },
    { id: PresenterDisplaySections.BILLING, title: 'Számlázási adatok', sort: 3 },
    //{ id: PresenterDisplaySections.PRICE, title: 'Végösszeg', sort: 4 },
  ].sort((a, b) => a.sort - b.sort);

  constructor(private readonly sessionService: SessionService) { }

  /**
   * Finds next tab element in tab list
   * Step indicates the direction from the current element
   * If step is positive number, it will find next element
   * If step is negative number, it will find previous element
   * If reached the very first / last element, will return null
   */
  private getNextTabElement(currentId: string, step: number): PresenterItem | null {
    const currentItemIndex = this.tabs.findIndex((item: PresenterItem) => item.id === currentId);
    if (currentItemIndex === this.tabs.length) { return null; }
    const nextItemIndex = currentItemIndex + step;
    return this.tabs[nextItemIndex];
  }

  /**
   * Set next tab element as active element
   */
  private setNextTabElement(element: PresenterItem | null): void {
    if (!element) { return; }
    this.setActiveTab(element.id);
  }

  /**
   * Set initial active tab element
   * This element will be active when component is rendered
   */
  private setInitialTabElement(): void {
    const presenterItem = this.tabs.at(0);
    if (presenterItem) { this.setActiveTab(presenterItem?.id); }
  }

  /**
   * Keyboard handler
   * For down and right it will find the next element in the tab list
   * For up and left it will find the previous element in the tab list
   */
  private keyHandler(e: KeyboardEvent): boolean {
    switch (e.key) {
      case 'Escape':
        this.deleteSessionToPresent();
        return true;
      case 'ArrowDown':
      case 'ArrowRight':
        e.preventDefault();
        this.setNextTabElement(this.getNextTabElement(this.activeTab, TabDirection.NEXT));
        return false;
      case 'ArrowUp':
      case 'ArrowLeft':
        e.preventDefault();
        this.setNextTabElement(this.getNextTabElement(this.activeTab, TabDirection.PREV));
        return false;
    }

    return true;
  }

  private deleteSessionToPresent(): void {
    this.sessionService.deleteSessionToPresent().subscribe({
      next: () => null, error: (e) => console.error(e)
    })
  }

  private sendActiveElementToScreen(id: number): void {
    this.sessionService.setSessionToPresent({ sessionId: id, section: this.activeTab }).subscribe({
      next: () => null,
      error: (e) => console.error(e)
    });
  }

  /**
   * On Destroy
   * It will remove event listener of keyboard
   * and send an undefined to the SSE handler to reset the displayed
   * state of the attached display
   */
  ngOnDestroy(): void {
    document.removeEventListener("keydown", this.keyboardHandler);
    this.deleteSessionToPresent();
  }

  ngOnInit(): void {
    document.addEventListener("keydown", this.keyboardHandler);
    this.setInitialTabElement();
  }

  public setActiveTab(tab: PresenterDisplaySections) {
    this.activeTab = tab;
    if (!this.sessionId) { this.deleteSessionToPresent(); }
    else { this.sendActiveElementToScreen(this.sessionId); }
  }
}
