import { Component } from '@angular/core';
import { MessageService } from 'src/app/services/message/message.service';
import { DisplayNames, Displays } from 'src/app/utils/displays';

@Component({
  selector: 'app-display-config',
  templateUrl: './display-config.component.html',
  styleUrls: ['./display-config.component.less']
})
export class DisplayConfigComponent {

  constructor(private readonly messageService: MessageService) { }

  public displays = Object.keys(Displays);

  public getDisplayName(display: string): string {
    return DisplayNames[display];
  }

  public saveConfiguration(displayId: string): void {
    localStorage.setItem('MACHINE_ID', displayId);
    this.messageService.createToast('Kijelző beállítva : ' + displayId);
  }

}
