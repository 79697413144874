import { Pipe, PipeTransform } from "@angular/core";
import { SessionState } from "../services/session.interface";

@Pipe({ name: "sessionState" })
export class SessionStatePipe implements PipeTransform {
    transform(value: string): string {
        switch (value) {
            case SessionState.ACTIVE:
                return 'Aktív';
            case SessionState.CLOSED:
                return 'Lezárt';
            case SessionState.DRAFT:
                return 'Piszkozat';
            case SessionState.SCHEDULED:
                return 'Ütemezett'
        }
        return "";
    }
}