import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Good, GoodType } from "./good.interface";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class GoodService {
  constructor(private readonly http: HttpClient) { }
  public getGoods(type?: string): Observable<Good[]> {
    const endpoint = type
      ? `${environment.api.hostname}/good/${type}`
      : `${environment.api.hostname}/good`;
    return this.http.get<Good[]>(endpoint);
  }

  public filterGood(dto: { [k: string]: string }): Observable<Good[]> {
    return this.http.post<Good[]>(`${environment.api.hostname}/good/filter`, dto);
  }

  public searchGood(type: GoodType, key: string): Observable<Good[]> {
    return this.http.post<Good[]>(`${environment.api.hostname}/good/search`, { type, key });
  }

  public getGood(id: number): Observable<Good> {
    return this.http.get<Good>(`${environment.api.hostname}/good/${id}`);
  }

  public createGood(good: Good): Observable<Good> {
    return this.http.post<Good>(`${environment.api.hostname}/good`, good);
  }

  public updateGood(good: Good, id: number): Observable<Good> {
    return this.http.patch<Good>(
      `${environment.api.hostname}/good/${id}`,
      good
    );
  }
}
