import { Component, Input } from '@angular/core';
import { ISession } from '../../../services/session.interface';

@Component({
  selector: 'app-session-details-measurements',
  templateUrl: './measurements.component.html',
  styleUrls: ['./measurements.component.less'],
})
export class MeasurementsComponent {
  @Input() session: ISession | undefined;
}
