import { Component, EventEmitter, Output } from '@angular/core';
import { GoodSubType, GoodType } from 'src/app/modules/good/services/good.interface';

enum ProductSKU {
  'moseco' = 'moseco',
  'mosnano' = 'mosnano',
  'mospro' = 'mospro',
  'SZOLG451' = 'SZOLG451',
  'SZOLG45' = 'SZOLG45',
  'SZOLG351' = 'SZOLG351',
  'SZOLG46' = 'SZOLG46',
  'packeco' = 'packeco',
  'packextr' = 'packextr',
  'packperf' = 'packperf',
  'packsuv' = 'packsuv',
  'HCAN250' = 'HCAN250',
  'XCAN250' = 'XCAN250',
  'ICCAN250' = 'ICCAN250'
}

@Component({
  selector: 'app-hot-links',
  templateUrl: './hot-links.component.html',
  styleUrls: ['./hot-links.component.less']
})
export class HotLinksComponent {

  public availableGoodSubTypes = GoodSubType;
  public availableGoodTypes = GoodType;

  public ProductElements: Record<ProductSKU, string> = {
    [ProductSKU.moseco]: 'Eco',
    [ProductSKU.mosnano]: 'Nano',
    [ProductSKU.mospro]: 'Protect',
    [ProductSKU.SZOLG451]: 'Futómű állítás',
    [ProductSKU.SZOLG45]: 'Futómű állítás első',
    [ProductSKU.SZOLG351]: 'Futómű állítás hátsó',
    [ProductSKU.SZOLG46]: 'Futómű diagnosztika',
    [ProductSKU.packeco]: 'Eco',
    [ProductSKU.packextr]: 'Extra',
    [ProductSKU.packperf]: 'Performance',
    [ProductSKU.packsuv]: 'SUV',
    [ProductSKU.HCAN250]: 'Hell 250ml CAN',
    [ProductSKU.XCAN250]: 'XIXO 250ml CAN',
    [ProductSKU.ICCAN250]: 'ICE COFFE 250ml CAN'
  }

  public productList: Record<GoodSubType, ProductSKU[]> = {
    [GoodSubType.WASH]: [ProductSKU.moseco, ProductSKU.mospro, ProductSKU.mosnano ],
    [GoodSubType.CARSERVICE]: [ProductSKU.SZOLG451, ProductSKU.SZOLG45, ProductSKU.SZOLG351, ProductSKU.SZOLG46],
    [GoodSubType.HOTEL]: [],
    [GoodSubType.INSTALL]: [ProductSKU.packeco, ProductSKU.packextr, ProductSKU.packsuv, ProductSKU.packperf],
    [GoodSubType.PRODUCT]: [],
    [GoodSubType.PROMOTION]: [ProductSKU.HCAN250, ProductSKU.XCAN250, ProductSKU.ICCAN250]
  };

  @Output() toggleValue: EventEmitter<{ subType: GoodSubType, type: GoodType }> = new EventEmitter();
  @Output() productSelected: EventEmitter<string> = new EventEmitter();

  public publishSelectedProduct(sku: string): void {
    this.productSelected.emit(sku);
  }

  public publish(type: GoodType, subType: GoodSubType): void {
    this.toggleValue.emit({ subType, type });
  }

}
