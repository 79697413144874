import { Routes } from "@angular/router";
import { UserListComponent } from "./components/user-list/user-list.component";
import { UserLoginComponent } from "./components/user-login/user-login.component";
import { UserLogoutComponent } from "./components/user-logout/user-logout.component";
import { UserPasswordForgotComponent } from "./components/user-password-forgot/user-password-forgot.component";
import { UserPasswordResetComponent } from "./components/user-password-reset/user-password-reset.component";
import { UserProfileComponent } from "./components/user-profile/user-profile.component";
import { UserCreateComponent } from "./components/user-create/user-create.component";
import { UserEditComponent } from "./components/user-edit/user-edit.component";

export const getUserModuleRoutes = (ActivationGuard: any): Routes => {
  return [
    {
      path: "user/create",
      component: UserCreateComponent,
      canActivate: [ActivationGuard],
      data: { showNavigation: true, componentTitle: "Felhasználó létrehozása" },
    },
    {
      path: "user/edit/:id",
      component: UserEditComponent,
      canActivate: [ActivationGuard],
      data: { showNavigation: true, componentTitle: "Felhasználó módosítása"  },
    },
    {
      path: "user/list",
      component: UserListComponent,
      canActivate: [ActivationGuard],
      data: { showNavigation: true, componentTitle: "Felhasználók listája"  },
    },
    {
      path: "user/profile",
      component: UserProfileComponent,
      canActivate: [ActivationGuard],
      data: { showNavigation: true, componentTitle: "Személyes beállítások"  },
    },
    {
      path: "user/login",
      component: UserLoginComponent,
      data: { showNavigation: false },
    },
    {
      path: "user/password-forgot",
      component: UserPasswordForgotComponent,
      data: { showNavigation: false },
    },
    {
      path: "user/password-reset/:hash",
      component: UserPasswordResetComponent,
      data: { showNavigation: false },
    },
    {
      path: "user/logout",
      component: UserLogoutComponent,
      canActivate: [ActivationGuard],
      data: { showNavigation: false },
    },
  ];
};
