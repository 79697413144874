<div class="flightboard-container">
    <table>
        <thead>
            <tr>
                <th class="dark-gray-bg rounded-border-left" width="400">Autó</th>
                <th class="dark-gray-bg">Fotó</th>
                <th class="dark-gray-bg">Tárhely</th>
                <th class="dark-gray-bg">Abroncs</th>
                <th class="dark-gray-bg rounded-border-right">Idő</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let session of sessions">
                <td class="blue-bg rounded-border-left">
                    <div class="car-plate">
                        <div class="plate">{{session.plate}}</div>
                        <div><app-session-flightboard-tyre-position [session]="session"></app-session-flightboard-tyre-position></div>
                    </div>
                </td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td class="rounded-border-right">
                    <app-session-countdown [session]="session"></app-session-countdown>
                </td>
            </tr>
        </tbody>
    </table>
</div>