<div>
    <div class="image-container">
        <img [src]="resource | safe" />
    </div>
    <div class="selector-wrapper">
        <div (click)="select(areaEnumeration.FRONT_LEFT)" class="selector-area half-size"></div>
        <div (click)="select(areaEnumeration.FRONT_RIGHT)" class="selector-area half-size"></div>
        <div (click)="select(areaEnumeration.REAR_LEFT)" class="selector-area third-size"></div>
        <div (click)="select(areaEnumeration.REAR_CENTER)" class="selector-area third-size"></div>
        <div (click)="select(areaEnumeration.REAR_RIGHT)" class="selector-area third-size"></div>
    </div>
</div>