import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { IContent } from "./content.interface";

@Injectable({
  providedIn: "root",
})
export class ContentService {

  constructor(private readonly http: HttpClient) { }

  public getContentsToPresent(): Observable<{ sessionId: number, displayId: string }> {
    return this.http.get<{ sessionId: number, displayId: string }>(`${environment.api.hostname}/content/present/`);
  }

  /**
   * Forces display to suspend content loops
   * and show session confirmation page
   */
  public hijackDisplayContent(displayId: string, sessionId: number) {
    return this.http.post(`${environment.api.hostname}/content/present/suppress`, {
      displayId, sessionId
    });
  }

  /**
   * Release display - removes forced session confirmation
   * page from display and that can go back for content looping
   */
  public releaseDisplayContent(displayId: string) {
    return this.http.post(`${environment.api.hostname}/content/present/release`, {
      displayId
    });
  }

  public createContent(dto: IContent) {
    return this.http.post<IContent>(`${environment.api.hostname}/content`, dto);
  }

  public updateContent(id: number, dto: IContent) {
    return this.http.patch<IContent>(`${environment.api.hostname}/content/${id}`, dto);
  }

  public deleteContent(id: number) {
    return this.http.delete(`${environment.api.hostname}/content/${id}`);
  }

  public getContents(): Observable<IContent[]> {
    return this.http.get<IContent[]>(`${environment.api.hostname}/content`);
  }

  public findContent(criteria: { [k: string]: string | number | boolean | null }): Observable<IContent[]> {
    return this.http.post<IContent[]>(`${environment.api.hostname}/content/find`, criteria);
  }

  public getContent(id: number): Observable<IContent> {
    return this.http.get<IContent>(`${environment.api.hostname}/content/${id}`);
  }

}
