import { Component, OnInit } from "@angular/core";
import { UserService } from "../../services/user.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MessageService } from "src/app/services/message/message.service";
import { Subscription } from "rxjs";
import { User, UserRoles } from "../../interfaces/user.interface";
import { ActivatedRoute } from "@angular/router";


@Component({
  selector: "app-user-edit",
  templateUrl: "./user-edit.component.html",
  styleUrls: ["./user-edit.component.less"],
})
export class UserEditComponent implements OnInit {
  constructor(
    private readonly userService: UserService,
    private readonly formBuilder: FormBuilder,
    private readonly messageService: MessageService,
    private route: ActivatedRoute
  ) {}

  public userServiceSubscription: Subscription = new Subscription();

  public user: User | undefined;

  public form: FormGroup | undefined;

  public roles = [
    { value: UserRoles.ADMIN, text: "Admin" },
    { value: UserRoles.SONGOKU, text: "SuperUser" },
  ];

  public fetchUser(userId:number) {
    if(this.userServiceSubscription) {this.userServiceSubscription.unsubscribe()}
    this.userServiceSubscription = this.userService.getUser(userId).subscribe({
      next:(user:User) => {
        this.user = user;
        this.form = this.formBuilder.group({
          name: [this.user.name, Validators.required],
          email: [this.user.email, Validators.required],
          role: [this.user.role, Validators.required],
          enabled: [this.user.enabled, Validators.required],
        });
      },
      error:(e) => this.messageService.createMessage(e.error.message,'error')
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe({
      next: (params:any) => {
        this.fetchUser(params.id);
      },
      error: (e) => console.error(e),
    });
  }

  public save() {
    if (!this.form) {
      return;
    }

    const user = {
      ...this.user,
      ...this.form.value,
    };

    this.userService.setUser(user).subscribe({
      next: () => {
        this.messageService.createMessage(
          "Felhasználó módosítása sikeres volt"
        ,'success');
        this.form?.reset();
        this.fetchUser(user.id);
      },
      error: (e) => this.messageService.createMessage(e.error.message, "error"),
    });
  }
}
