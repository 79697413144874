<div class="wrapper">
    <div *ngIf="session">
        <div class="row">
            <div class="column">
                <div *ngIf="activeState === 'MEASUREMENTS'">
                    <app-session-details-measurements [session]="session"></app-session-details-measurements>
                </div>
                <div *ngIf="activeState === 'PHOTOS'">
                    <app-session-details-photos [session]="session"></app-session-details-photos>
                </div>
                <div *ngIf="activeState === 'PRODUCTS'">
                    <app-session-details-products [session]="session"></app-session-details-products>
                </div>
                <div *ngIf="activeState === 'BILLING'">
                    <app-session-details-billing [session]="session"></app-session-details-billing>
                </div>
                <div *ngIf="activeState === 'PRICE'">
                    <app-session-details-price [session]="session"></app-session-details-price>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!session">
        <h1 class="text-center no-data-label">Hello!</h1>
    </div>
</div>