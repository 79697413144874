import { Component, Input } from '@angular/core';
import { ISession } from '../../../services/session.interface';

@Component({
  selector: 'app-session-details-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.less']
})
export class ProductsComponent {
  @Input() session: ISession | undefined;
}
