<div>
    <label for="">Termék keresése</label>
    <div class="row">
        <div class="column">
            <select name="stock-filter" (change)="setStockFilter($event)">
                <option value="{{stockFilterOptions.ALL}}">Minden termék és szolgáltatás</option>
                <option value="{{stockFilterOptions.STOCK_ONLY}}">Csak készlettel rendelkező</option>
            </select>
        </div>
        <div class="column">
            <input type="text" #filterInput>
        </div>
    </div>
</div>

<div class="filters">
    <span class="filter-button" *ngFor="let filter of categoryFiltersMap[goodSubType]" [ngClass]="{'active':categoryFilter === filter}"
        (click)="setCategoryFilter(filter)">
        {{filter}}
    </span>
</div>

<div class="element-list">

    <div *ngIf="!loading && list.raw.length === 0">
        <div style="text-align: center;padding:20px;">
            <i class="fa fa-ban"></i> Nincs megjeleníthető adat...
        </div>
    </div>
    <div *ngIf="loading">
        <div style="text-align: center;padding:20px;">
            <i class="fa fa-spinner fa-spin"></i> Betöltés folyamatban...
        </div>
    </div>
    <table *ngIf="!loading && list.raw.length > 0">
        <thead>
            <tr>
                <th width="120">Cikkszám</th>
                <th width="370">Megnevezés</th>
                <th width="110">Típus</th>
                <th width="110">Bruttó Ár</th>
                <th width="110">Készlet</th>
                <th width="110">Művelet</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of list.filtered">
                <td>{{item.sku}}</td>
                <td>{{item.title}}</td>
                <td>{{item.type | goodType}}</td>
                <td class="price-text">{{formatPrice(item) | number}} Ft</td>
                <td>{{item.stock}}</td>
                <td><a (click)="select(item)" class="add-to-cart-btn">Kosárba</a></td>
            </tr>
        </tbody>
    </table>
</div>