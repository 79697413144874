<div class="container">
    <div class="row" [formGroup]="form">
        <div class="column">
            <div class="row">
                <div class="column">
                    <label for="email">Név</label>
                    <input type="text" formControlName="name" name="name" id="name" placeholder="Név" required>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <label for="email">E-mail cím</label>
                    <input type="email" formControlName="email" name="email" id="email" placeholder="E-mail cím" required>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <label for="email">Szerepkör</label>
                    <select name="role" id="role" formControlName="role" required>
                        <option *ngFor="let role of roles" value="{{role.value}}">{{role.text}}</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <b>Információ</b>
                    <p>Az újonnan létrehozott felhasználó átmeneti jelszóval kerül létrehozásra.<br>
                    A létrehozást követően a felhasználó e-mail-ben kap egy linket, ahol beállíthatja saját jelszavát.</p>
                </div>
            </div>
        </div>
    </div>
    <hr>
    <button class="button" (click)="save()" [disabled]="!form.valid">Felhasználó létrehozása</button>
</div>