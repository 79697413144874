import { Component, OnInit } from "@angular/core";
import { UserService } from "../../services/user.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { UserLink } from "../../interfaces/user.interface";
import { MessageService } from "src/app/services/message/message.service";

@Component({
  selector: "app-user-password-reset",
  templateUrl: "./user-password-reset.component.html",
  styleUrls: ["./user-password-reset.component.less"],
})
export class UserPasswordResetComponent implements OnInit {
  constructor(
    private readonly userService: UserService,
    private readonly formBuilder: FormBuilder,
    private readonly messageService: MessageService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  public userLink: any;
  public form: FormGroup = this.formBuilder.group({});

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: ["", Validators.required],
      hash: ["", Validators.required],
      password: ["", Validators.required],
      passwordRepeat: ["", Validators.required],
    });

    const hash = this.activatedRoute.snapshot.paramMap.get("hash");

    this.userService.validatePasswordResetHash(hash).subscribe({
      next: (result: UserLink) => {
        this.form.get("email")?.setValue(result?.email);
        this.form.get("hash")?.setValue(result?.hash);
      },
      error: (e) => {
        this.messageService.createMessage(e.error.message, "error");
        this.router.navigate(["/"]);
      },
    });
  }

  public save() {
    this.userService.resetPassword(this.form.value).subscribe({
      next: () => {
        this.messageService.createMessage(
          "A jelszó helyreállítása sikeres volt! Most már bejelentkezhet!"
        ,'success');
        this.form?.reset();
        this.router.navigate(["/"]);
      },
      error: (e) => this.messageService.createMessage(e.error.message, "error"),
    });
  }
}
