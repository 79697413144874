import { Component, Input } from '@angular/core';
import { ISession } from '../../../services/session.interface';

@Component({
  selector: 'app-session-details-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.less']
})
export class PriceComponent {
  @Input() session: ISession | undefined;
}
