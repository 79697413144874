/**
 * Get machine / workstation id from local storage
 */
export const getWorkstationId = () => localStorage.getItem("MACHINE_ID");

/**
 * Machine that related to the actual machine
 * It could be a display or anything else.
 * Displays also identified by workstation id, this identifier defines a relation
 */
export const getRelatedWorkstationId = () => localStorage.getItem('RELATED_MACHINE_ID');
