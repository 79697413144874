import { Component, Input, OnInit } from '@angular/core';
import { ISession, ISessionProduct, WheelPosition, wheelPositionShortMapReversed } from '../../../services/session.interface';

@Component({
  selector: 'app-session-flightboard-tyre-position',
  templateUrl: './session-flightboard-tyre-position.component.html',
  styleUrls: ['./session-flightboard-tyre-position.component.less']
})
export class SessionFlightboardTyrePositionComponent implements OnInit {
  @Input() session: ISession | undefined;

  public positions: Record<WheelPosition, boolean> = {
    [WheelPosition.FRONT_LEFT]: false,
    [WheelPosition.FRONT_RIGHT]: false,
    [WheelPosition.REAR_LEFT]: false,
    [WheelPosition.REAR_CENTER]: false,
    [WheelPosition.REAR_RIGHT]: false,
  };

  ngOnInit(): void {

    this.session?.products.forEach((item: ISessionProduct) => {

      const positions = item.position.split(',');
      positions.forEach((position) => {
        const wheelPositionKey = wheelPositionShortMapReversed[position];
        this.positions[wheelPositionKey] = true;
      });

    })
  }
}
