<div class="login-container">
    <h3>AutoDrom <small>Vezérlőpult</small></h3>
    <div class="login-form">
        <form class="" [formGroup]="loginForm" (ngSubmit)="login()">
            <div>
                <label>E-mail cím</label>
                <input autofocus type="email" name="email" id="email" class="form-control" formControlName="email">
            </div>
            <div>

                <label>Jelszó</label>
                <input type="password" name="password" id="password" class="form-control" formControlName="password">

            </div>
            <div>
                <button type="submit">Hitelesítés</button>
            </div>
            <div class="form-group text-center">
                <a class="link-secondary" [routerLink]="['/user/password-forgot']">Elfelejtett jelszó helyreállítása</a>
            </div>
        </form>
    </div>
</div>