import { Routes } from "@angular/router";
import { SessionDashboardComponent } from "./components/session-dashboard/session-dashboard.component";
import { SessionFlightboardComponent } from "./components/session-flightboard/session-flightboard.component";
import { SessionCreateComponent } from "./components/session-create/session-create.component";
import { SessionDetailsComponent } from "./components/session-details/session-details.component";

export const getSessionModuleRoutes = (ActivationGuard: any): Routes => {
  return [
    {
      path: "session/board",
      component: SessionFlightboardComponent,
      canActivate: [ActivationGuard],
      data: { showNavigation: false, componentTitle: "", subType:'flightboard' },
    },
    {
      path: "session/create",
      component: SessionCreateComponent,
      canActivate: [ActivationGuard],
      data: { showNavigation: true, componentTitle: "Munkamenet létrehozása" },
    },
    {
      path: "session/details",
      component: SessionDetailsComponent,
      canActivate: [ActivationGuard],
      data: { showNavigation: false, componentTitle: "Munkamenet részletei", subType:'sessionDetails' },
    },
    {
      path: "session/edit/:id",
      component: SessionCreateComponent,
      canActivate: [ActivationGuard],
      data: { showNavigation: true, componentTitle: "Munkamenet módosítása" },
    },
    {
      path: "session/list",
      component: SessionDashboardComponent,
      canActivate: [ActivationGuard],
      data: { showNavigation: true, componentTitle: "Munkamenetek listája" },
    },
  ];
};
