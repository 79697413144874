<div class="confirm-modal-backdrop" *ngIf="modalVisible"></div>

<ng-container *ngIf="session">

    <div class="container">

        <div class="confirm-modal" *ngIf="modalVisible">
            <div class="confirm-modal-body">
                <p><img src="/assets/check.svg" alt="" width="80"></p>
                <p>Kezdődjön a móka baby</p>
                <p class="direction-label">Irány a {{getGateSuffix(session.gate)}} kapu</p>
            </div>
        </div>

        <div class="logo">
            <img src="/assets/ADblack.svg" alt="">
        </div>

        <div class="head">
            <h1>{{session.name}}</h1>
            <h2>{{session.phone.replace('###','')}}</h2>
            <h3>{{session.plate}}</h3>
        </div>

        <div class="body">
            <table>
                <thead>
                    <tr>
                        <th class="blue-bg rounded-border-left" width="60%">Szolgáltatás</th>
                        <th class="dark-gray-bg">Nettó</th>
                        <th class="dark-gray-bg">Áfa</th>
                        <th class="dark-gray-bg">Mennyiség</th>
                        <th class="dark-gray-bg rounded-border-right">Bruttó</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of session.products">
                        <td>{{item.title}}</td>
                        <td>{{item.unitPrice | number}} Ft</td>
                        <td>{{(item.totalPrice / item.quantity) - item.unitPrice | number}}</td>
                        <td>{{item.quantity}} db</td>
                        <td>{{item.totalPrice | number}} Ft</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td style="text-align: center;" colspan="5">
                            <span class="total-price dark-gray-bg">Összesen {{session.totalPrice | number}} Ft</span>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>


        <div class="order-mimic">
            Megrendelem
        </div>

    </div>

</ng-container>