import { Component, OnInit } from '@angular/core';
import { IContent } from '../../services/content.interface';
import { DisplayNames, Displays } from 'src/app/utils/displays';
import { ContentService } from '../../services/content.service';
import { MessageService } from 'src/app/services/message/message.service';
import { SweetAlertResult } from 'sweetalert2';
import { Days, DaysShortMap } from 'src/app/utils/calendar';
import { ModalState } from 'src/app/utils/modal';
import { DisplayService, DisplaySetting } from 'src/app/services/display/display.service';

interface SelectedDays {
  [key: string]: boolean
}

interface IContentCreationForm extends IContent {
  selectedDays: SelectedDays
}

interface IDisplaySettingsForm {
  displayId: string;
  width: number;
  height: number;
}

enum ModalWindows {
  'CREATION' = 'CREATION',
  'DISPLAY_SETTINGS' = 'DISPLAY_SETTINGS'
}

@Component({
  selector: 'app-content-manager',
  templateUrl: './content-manager.component.html',
  styleUrls: ['./content-manager.component.less']
})
export class ContentManagerComponent implements OnInit {

  public modalState: ModalState = ModalState.CLOSED;
  public modalSelected: ModalWindows | undefined;

  public contents: Array<IContent> = [];

  public contentToDisplayMap: Record<string, IContent[]> = {};

  public defaultKeepFor = 5;

  public creationForm: IContentCreationForm | undefined;

  public settingsForm: IDisplaySettingsForm | undefined;

  public displays = Object.keys(Displays);

  public modalWindows = ModalWindows;

  public displaySelected: string | undefined;

  public displaySettings: DisplaySetting[] = [];

  public defaultSelectedDays: SelectedDays = {
    [Days.MONDAY]: true,
    [Days.TUESDAY]: true,
    [Days.WEDNESDAY]: true,
    [Days.THURSDAY]: true,
    [Days.FRIDAY]: true,
    [Days.SATURDAY]: false,
    [Days.SUNDAY]: false
  };

  private escapeKeyHandler = this.escapeHandler.bind(this);

  constructor(private readonly contentService: ContentService, private readonly messageService: MessageService, private readonly displayService: DisplayService) { }

  public getDisplayName(display?: string): string {
    if (!display) { return ""; }
    return DisplayNames[display];
  }

  public getResourceList(display: string): IContent[] {
    return this.contentToDisplayMap[display];
  }

  private escapeHandler(e: KeyboardEvent): void {
    if (e.key === "Escape") {
      this.toggleCreationModalWindow();
    }
  }

  /**
   * Load resource list
   */
  public loadResourceList(): void {
    this.contentService.getContents().subscribe({
      next: (contents: IContent[]) => {
        this.contents = contents;
        this.displays.forEach((display: string) => {
          this.contentToDisplayMap[display] = this.contents.filter((content: IContent) => content.displayId === display);
        });
      },
      error: (e) => this.messageService.createMessage(e.error.message, 'error')
    })
  }

  public toggleDisplaySettingsModalWindow(displayId?: string): void {

    this.modalState = this.modalState === ModalState.CLOSED ? ModalState.OPEN : ModalState.CLOSED;

    this.settingsForm = displayId ? this.initializeSettingsForm(displayId) : undefined;

    if (this.modalState === ModalState.OPEN) {
      document.addEventListener("keyup", this.escapeKeyHandler);
      this.modalSelected = ModalWindows.DISPLAY_SETTINGS;
      this.displaySelected = displayId;
    }

    if (this.modalState === ModalState.CLOSED) {
      document.removeEventListener("keyup", this.escapeKeyHandler);
      this.modalSelected = undefined;
      this.displaySelected = undefined;
    }
  }

  /**
   * Toggle creation modal window
   * Used by resource add functionality
   */
  public toggleCreationModalWindow(displayId?: string): void {

    this.modalState = this.modalState === ModalState.CLOSED ? ModalState.OPEN : ModalState.CLOSED;
    this.creationForm = displayId ? this.initializeCreationForm(displayId) : undefined;

    if (this.modalState === ModalState.OPEN) {
      document.addEventListener("keyup", this.escapeKeyHandler);
      setTimeout(() => {
        document.getElementById('resourceUrlInput')?.focus();
      }, 300);
      this.modalSelected = ModalWindows.CREATION;
    }

    if (this.modalState === ModalState.CLOSED) {
      document.removeEventListener("keyup", this.escapeKeyHandler);
      this.creationForm = undefined;
      this.modalSelected = undefined;
    }
  }

  /**
   * Add new resource
   */
  public addResource(): void {

    if (!this.creationForm) { return; }

    if (!this.creationForm.resource) {
      this.messageService.createMessage('A hivatkozás mező kitöltése kötelező!', 'error');
      return;
    }

    if (!this.creationForm.keepFor) {
      this.creationForm.keepFor = this.defaultKeepFor;
      return;
    }

    const selectedDaysArray = [];

    for (const index in this.creationForm.selectedDays) {
      if (this.creationForm.selectedDays[index]) {
        selectedDaysArray.push(DaysShortMap[index as Days]);
      }
    }

    if (selectedDaysArray.length === 0) {
      this.messageService.createMessage('Legalább 1 aktív nap kiválasztása kötelező!', 'error');
      return;
    }

    this.creationForm.days = selectedDaysArray.join(',');

    this.contentService.createContent(this.creationForm).subscribe({
      next: () => {
        this.loadResourceList();
        this.toggleCreationModalWindow();
        this.messageService.createToast('Tartalom létrehozva!');
      },
      error: (e) => this.messageService.createMessage(e.error.message, 'error')
    })

  }

  /**
   * Remove resource by id
   */
  public removeResource(id: number | undefined): void {
    if (!id) { return; }
    this.messageService.createConfirmation('Valóban törölni szeretné a kiválasztott element?', 'question').then((result: SweetAlertResult) => {
      if (result && result.isConfirmed) {
        this.contentService.deleteContent(id).subscribe({
          next: () => {
            this.loadResourceList();
            this.messageService.createToast('Tartalom törölve')
          },
          error: (e) => this.messageService.createMessage(e.error.message, 'error')
        })
      }
    })

  }

  public saveDisplaySettings(): void {
    if (!this.displaySelected || !this.settingsForm) { console.error('APP:No display selected or settings form undefined'); return; }
    this.displayService.saveDisplaySettings(this.settingsForm).subscribe({
      next: (result: any) => {
        this.toggleDisplaySettingsModalWindow();
        this.messageService.createMessage('Beállítások tárolása sikeres!', 'success')
      },
      error: (e) => this.messageService.createMessage(e.error.error, 'error')
    })
  }

  public initializeSettingsForm(displayId: string): IDisplaySettingsForm {
    const settings = this.displaySettings.find((stored: DisplaySetting) => stored.displayId === displayId) ?? { displayId, width: 0, height: 0 };
    return settings;
  }

  /**
   * Initialize creation form
   */
  public initializeCreationForm(displayId: string): IContentCreationForm {
    return {
      days: "",
      resource: "",
      keepFor: this.defaultKeepFor,
      intervalEnds: '00:00',
      intervalStarts: '00:00',
      displayId: displayId,
      selectedDays: { ...this.defaultSelectedDays }
    };
  }

  ngOnInit(): void {
    this.loadResourceList();
    this.displayService.getDisplaySettings().subscribe({
      next: (settings: DisplaySetting[]) => {
        this.displaySettings = settings;
      },
      error: (e) => this.messageService.createMessage(e.error, 'error')
    })
  }

}
