import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ISession, SessionState, SessionType } from '../../services/session.interface';
import { SessionService } from '../../services/session.service';

const SESSION_LOADING_INTERVAL_TIMEOUT = 3 * 1000;

@Component({
  selector: 'app-session-flightboard',
  templateUrl: './session-flightboard.component.html',
  styleUrls: ['./session-flightboard.component.less']
})
export class SessionFlightboardComponent implements OnInit, OnDestroy {

  public sessions: Array<ISession> = [];
  public sessionLoadingInterval: any | undefined = undefined;
  public sessionDataSubscription: Subscription = new Subscription();

  constructor(private readonly sessionService: SessionService) { }

  private sameResponse(sessions: ISession[]): boolean {
    return JSON.stringify(sessions) === JSON.stringify(this.sessions);
  }

  private setSessions(sessions: ISession[]): void {
    this.sessions = sessions;
  }

  private getSessions(): void {

    if (this.sessionDataSubscription) {
      this.sessionDataSubscription.unsubscribe();
    }

    this.sessionDataSubscription = this.sessionService.searchSession(
      { where: { sessionState: SessionState.ACTIVE, sessionType: SessionType.SERVICE }, limit: 4 }).subscribe({
        next: (sessions: ISession[]) => {
          if (this.sameResponse(sessions) === false) {
            this.setSessions(sessions);
          }
        },
        error: (e) => console.error(e),
      });
  }

  ngOnInit(): void {
    this.getSessions();
    this.sessionLoadingInterval = setInterval(() => this.getSessions(), SESSION_LOADING_INTERVAL_TIMEOUT);
  }

  ngOnDestroy(): void {

    // reset data load subscription
    if (this.sessionDataSubscription) {
      this.sessionDataSubscription.unsubscribe();
    }

    // reset refresh interval
    if (this.sessionLoadingInterval) {
      clearInterval(this.sessionLoadingInterval);
    }

  }

}
