import { Component, OnDestroy, OnInit } from "@angular/core";
import { User } from "../../interfaces/user.interface";
import { UserService } from "../../services/user.service";
import { AuthService } from "../../services/auth.service";
import { MessageService } from "src/app/services/message/message.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-user-list",
  templateUrl: "./user-list.component.html",
  styleUrls: ["./user-list.component.less"],
})
export class UserListComponent implements OnInit, OnDestroy {
  public users: User[] = [];
  public usersFiltered: User[] = [];

  constructor(
    private readonly userService: UserService,
    private readonly authService: AuthService,
    private readonly messageService: MessageService
  ) {}

  public currentUser = this.authService.getAuthenticatedUser();

  public usersSubscription: Subscription = new Subscription();

  private fetchUsers() {
    this.usersSubscription = this.userService.getUsers().subscribe({
      next: (users: User[]) => {
        this.users = users;
        this.usersFiltered = users;
      },
      error: (e) => console.error(e),
    });
  }

  public filter(term: string) {
    if (!term) {
      this.usersFiltered = this.users;
      return;
    }
    this.usersFiltered = this.users.filter((user) => {
      return user.name?.includes(term) || user.email?.includes(term);
    });
  }


  ngOnDestroy(): void {
    this.usersSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.fetchUsers();
  }
}
