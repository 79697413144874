import { Component, Input } from '@angular/core';
import { ISession } from '../../../services/session.interface';
import { PaymentMethod, PaymentMethodMap } from 'src/app/utils/payment-methods';

@Component({
  selector: 'app-session-details-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.less']
})
export class BillingComponent {
  @Input() session: ISession | undefined;
  public getPaymentMethodName(method: string): string {
    return PaymentMethodMap[method as unknown as PaymentMethod];
  }
}
