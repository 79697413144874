import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { User } from "../../interfaces/user.interface";
import { AuthService } from "../../services/auth.service";
import { UserService } from "../../services/user.service";
import { MessageService } from "src/app/services/message/message.service";

@Component({
  selector: "app-user-login",
  templateUrl: "./user-login.component.html",
  styleUrls: ["./user-login.component.less"],
  encapsulation: ViewEncapsulation.None,
})
export class UserLoginComponent implements OnInit {
  public loginForm = new FormGroup({
    email: new FormControl("", [Validators.required]),
    password: new FormControl("", [Validators.required]),
  });

  constructor(
    private readonly authService: AuthService,
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly messageService: MessageService
  ) {}

  login() {
    if (this.loginForm.invalid) {
      return;
    }

    this.authService
      .authenticate(
        this.loginForm.get("email")?.value,
        this.loginForm.get("password")?.value
      )
      .subscribe({
        error: (response) => {
          this.messageService.createMessage(response.error.message, "error");
        },
        next: (response: any) => {
          this.authService.setAccessToken(response.access_token);

          this.userService.getUserProfile().subscribe({
            error: (error) => {
              this.messageService.createMessage(error.error.message, "error");
            },
            next: (user: User) => {
              if (
                !this.authService.isAdminUser(user) &&
                !this.authService.isSuperUser(user)
              ) {
                this.messageService.createMessage(
                  "Hozzáférés megtagadva",
                  "error"
                );
              } else {
                this.authService.setAuthenticatedUser(user);
              }
              this.router.navigate(["/"]);
            },
          });
        },
      });
  }

  ngOnInit(): void {
    // avoid loading when user authenticated
    if (this.authService.getAuthenticatedUser()) {
      this.router.navigate(["/"]);
      return;
    }
  }
}
