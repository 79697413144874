import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { getCurrentTimestamp } from "src/app/utils/timestamp";
import { ISession } from "../../services/session.interface";

@Component({
  selector: "app-session-countdown",
  templateUrl: "./session-countdown.component.html",
  styleUrls: ["./session-countdown.component.less"],
})
export class SessionCountdownComponent implements OnInit, OnDestroy {
  @Input() session: ISession | undefined;
  private interval: any;
  public countdown = 0;

  private calculateCountDown(creationTimestamp: string) {

    const averageTimeInMinutes = this.session?.maximumEffortInMinutes || 24;
    const currentTime = getCurrentTimestamp();
    const createdTime = new Date(creationTimestamp + ".000Z").getTime() / 1000;
    const difference = Math.round(Math.round(currentTime - createdTime) / 60); // difference in minutes

    // time is up - delete counting
    if (difference > averageTimeInMinutes) {
      this.countdown = 0;
      clearInterval(this.interval);
      return;
    }

    // set countdown value
    this.countdown = averageTimeInMinutes - difference;

    // setup timer if its not setted up earlier
    if (!this.interval) {
      this.interval = setInterval(() => {
        this.calculateCountDown(creationTimestamp);
      }, 60000);
    }
  }

  ngOnInit(): void {
    if (this.session?.createdAt) {
      this.calculateCountDown(this.session.createdAt);
    }
  }
  ngOnDestroy(): void {
    clearInterval(this.interval);
  }
}
