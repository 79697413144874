import { Component } from "@angular/core";
import { UserService } from "../../services/user.service";
import { FormBuilder, Validators } from "@angular/forms";
import { createRandomString } from "src/app/utils/random-string";
import { MessageService } from "src/app/services/message/message.service";
import { UserRoles } from "../../interfaces/user.interface";

@Component({
  selector: "app-user-create",
  templateUrl: "./user-create.component.html",
  styleUrls: ["./user-create.component.less"],
})
export class UserCreateComponent {
  constructor(
    private readonly userService: UserService,
    private readonly formBuilder: FormBuilder,
    private readonly messageService: MessageService
  ) {}

  private randomPassword = createRandomString(32);

  public roles = [
    { value: UserRoles.ADMIN, text: "Admin" },
    { value: UserRoles.SONGOKU, text: "SuperUser" },
  ];

  public form = this.formBuilder.group({
    name: ["", Validators.required],
    email: ["", Validators.required],
    role: [UserRoles.ADMIN, Validators.required],
    password: [this.randomPassword, Validators.required],
    passwordRepeat: [this.randomPassword, Validators.required],
  });

  public save() {
    this.userService.createUser(this.form.value).subscribe({
      next: () => {
        this.messageService.createMessage(
          "Felhasználó létrehozása sikeres volt"
        ,'success');
        this.form.reset();
      },
      error: (e) => this.messageService.createMessage(e.error.message, "error"),
    });
  }
}
