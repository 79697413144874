import { Component, OnDestroy, OnInit } from '@angular/core';
import { ContentService } from '../../services/content.service';
import { getWorkstationId } from 'src/app/utils/identify-machine';
import { Subscription } from 'rxjs';

const LOADING_INTERVAL_TIMEOUT = 3000;

@Component({
  selector: 'app-content-presenter',
  templateUrl: './content-presenter.component.html',
  styleUrls: ['./content-presenter.component.less']
})
export class ContentPresenterComponent implements OnInit, OnDestroy {

  constructor(private readonly contentService: ContentService) { }

  public displayId = getWorkstationId();
  public resource: number | undefined;
  public loadingIntervalHandler: any = undefined;
  public loadingSubscription: Subscription | undefined;

  public getContents(): void {

    // reset subsscription before start
    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }

    // build up new subscription
    this.loadingSubscription = this.contentService.getContentsToPresent().subscribe({
      next: (items) => {

        // no shit, the list is empty, reset current state
        if (!items || !Array.isArray(items) || items.length === 0) {
          if (this.resource) {
            setTimeout(() => { this.resource = undefined; }, 5000);
          }
          return;
        }

        // find resource related to this display
        const contentItem = items.find((item: { sessionId: number, displayId: string }) => item.displayId && this.displayId && item.displayId === this.displayId);
        if (!contentItem) {
          return;
        }

        // same resource to show, nothing to do
        if (contentItem.sessionId === this.resource) { return; }

        this.resource = contentItem.sessionId;
      },
      error: (e) => console.error(e)
    })
  }

  public ngOnDestroy(): void {
    clearInterval(this.loadingIntervalHandler);
    this.loadingSubscription?.unsubscribe();
  }

  public ngOnInit(): void {
    this.getContents();
    this.loadingIntervalHandler = setInterval(() => this.getContents(), LOADING_INTERVAL_TIMEOUT);
  }
}
