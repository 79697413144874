<div class="row">
    <div class="column">
        <div class="tabs">
            <ul>
                <li *ngFor="let item of tabs">
                    <a tabindex="1" [ngClass]="{'active':activeTab == item.id}"
                        (click)="setActiveTab(item.id)">{{item.title}}</a>
                </li>
            </ul>
        </div>
    </div>
</div>