import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { MessageService } from "../message/message.service";

@Injectable({
  providedIn: "root",
})
export class TextMessageService {

  constructor(private readonly http: HttpClient, private readonly messageService: MessageService) { }

  public async sendDetailsPageMessage(hash:string, phone:string): Promise<boolean> {
    
    if (!hash || !phone || phone.length === 0) { return Promise.reject(false); }
    const number = phone.replaceAll('#', '').replace('+', '');

    this.sendMessage(number, `${environment.services.sessionDetailsPage}?hash=` + hash).subscribe({
      next: () => {
        this.messageService.createMessage(`Üzenet elküldve a következő telefonszámra<br><br><b>${phone}</b>`, 'success');
        return Promise.resolve(true);
      },
      error: (e) => {
        this.messageService.createMessage(e.message, 'error');
        return Promise.reject(false);
      }
    })
    return Promise.reject(false);
  }

  private sendMessage(number: string, message: string) {
    return this.http.post(`${environment.api.hostname}/sms/send`, {
      number: Number(number),
      message
    });
  }
}
