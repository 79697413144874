<div class="container">
    <div class="row">
        <div class="column toolbar">
            <button class="button" (click)="save()">Termék mentése</button>
        </div>
    </div>
    <hr>
    <form *ngIf="form" [formGroup]="form">
        <div class="row">
            <div class="column column-25 main-col">
                <div class="row">
                    <div class="column">
                        <label for="">Megnevezés *</label>
                        <input type="text" formControlName="title" name="title" id="title" placeholder="Megnevezés" required
                            autofocus>
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <label for="">Leírás</label>
                        <input name="description" formControlName="description" id="description" placeholder="Leírás">
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <label for="">Cikkszám *</label>
                        <input type="text" name="sku" id="sku" formControlName="sku" placeholder="Cikkszám" readonly>
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <label for="">Vonalkód *</label>
                        <input type="text" name="barcode" id="barcode" formControlName="barcode" placeholder="Vonalkód">
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <label for="">Elkészülési idő (perc) *</label>
                        <input type="number" name="minutes" id="minutes" formControlName="minutes" placeholder="Elkészülési idő">
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <label for="">Színkód *</label>
                        <input type="color" name="color" id="color" formControlName="color" placeholder="Színkód">
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <label>Beállítások</label>
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <input type="checkbox" name="isPack" id="isPack" formControlName="isPack">
                        <span>Csomag ajánlat</span>
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <input type="checkbox" name="enabled" id="enabled" formControlName="enabled">
                        <span>Aktív</span>
                    </div>
                </div>
            </div>
            <div class="column column-25 main-col">
                <div class="row">
                    <div class="column">
                        <label for="">Típus *</label>
                        <select name="type" id="type" formControlName="type">
                            <option value="PRODUCT">Termék</option>
                            <option value="SERVICE">Szolgáltatás</option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <label for="">Altípus *</label>
                        <select name="subType" id="subType" formControlName="subType">
                            <option value="WASH">Mosási szolgáltatás</option>
                            <option value="INSTALL">Szervíz szolgáltatás</option>
                            <option value="PRODUCT">Termék</option>
                            <option value="HOTEL">Tárolás</option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <label for="">Nettó egységár *</label>
                        <input type="text" name="price" id="price" placeholder="Nettó darabár" formControlName="price">
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <label for="">Áfa (%) *</label>
                        <input type="text" name="vat" id="vat" placeholder="Áfa (%)" formControlName="vat">
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <label for="">Kedvezmény (%) *</label>
                        <input type="text" name="discount" id="discount" placeholder="Kedvezmény (%)"
                            formControlName="discount">
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <label for="">Aktuális készlet *</label>
                        <input type="text" name="stock" id="stock" placeholder="Aktuális készlet"
                            formControlName="stock">
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <label for="">Termékcsoport</label>
                        <input type="text" name="category" id="category" formControlName="category" placeholder="Termékcsoport" readonly>
                    </div>
                </div>
            </div>

        </div>
    </form>
</div>