import { Component, EventEmitter, Output } from '@angular/core';
import { WheelPosition, wheelPositionShortMap } from '../../../services/session.interface';

// assets/wheel/empty.svg
const DEFAULT_ASSET_ID = 'empty';

/**
 * This separator used in stringify method
 * to concatenate values with
 */
const INNER_CONCATENATION_SEPARATOR = '_';

/**
 * This separator is used during publish
 * selected values as other parts of the
 * system uses different concatenation format
 * for wheel selection - position values
 * This string keeps the compatiblity
 */
const OUTER_CONCATENATION_SEPARATOR = ',';

@Component({
  selector: 'app-wheel-selector',
  templateUrl: './wheel-selector.component.html',
  styleUrls: ['./wheel-selector.component.less']
})
export class WheelSelectorComponent {

  public assetID: string = DEFAULT_ASSET_ID;

  public resource = this.setResourcePath(this.assetID);

  public areaEnumeration = WheelPosition;

  @Output() toggleValue: EventEmitter<string> = new EventEmitter();

  public area: Record<WheelPosition, boolean> = {
    [WheelPosition.FRONT_LEFT]: false,
    [WheelPosition.FRONT_RIGHT]: false,
    [WheelPosition.REAR_LEFT]: false,
    [WheelPosition.REAR_CENTER]: false,
    [WheelPosition.REAR_RIGHT]: false,
  };

  public areaShortMap: Record<WheelPosition, string> = {
    [WheelPosition.FRONT_LEFT]: 'FL',
    [WheelPosition.FRONT_RIGHT]: 'FR',
    [WheelPosition.REAR_LEFT]: 'RL',
    [WheelPosition.REAR_CENTER]: 'RC',
    [WheelPosition.REAR_RIGHT]: 'RR'
  }

  /**
   * Area selection click handler
   */
  public select(selected: WheelPosition): void {
    this.area[selected as unknown as WheelPosition] = !this.area[selected as unknown as WheelPosition];
    this.setResource();
    this.publish();
  }

  /**
   * Resets selected areas to default state
   */
  private reset(): void {
    Object.keys(this.area).forEach((area: string) => this.area[area as keyof typeof WheelPosition] = false);
  }

  /**
   * Sets resource and asset id
   */
  private setResource() {
    this.assetID = this.stringify() || DEFAULT_ASSET_ID;
    this.resource = this.setResourcePath(this.assetID);
  }

  /**
   * Sets resource path
   */
  private setResourcePath(assetId: string): string {
    return `/assets/wheel/${assetId}.svg`;
  }

  /**
   * Publish changes to parent component
   */
  private publish(): void {
    const value = this.assetID === DEFAULT_ASSET_ID ? '' : this.assetID;
    this.toggleValue.emit(value.replaceAll(INNER_CONCATENATION_SEPARATOR, OUTER_CONCATENATION_SEPARATOR));
  }

  /**
   * Stringify selected areas
   * and gives back the mapped short names
   * of the selected areas
   */
  private stringify(): string {
    const selected = Object.keys(this.area).filter((item: string) => this.area[item as keyof typeof WheelPosition] === true);
    return selected.reduce((accum: Array<string>, current) => {
      accum.push(wheelPositionShortMap[current as keyof typeof WheelPosition])
      return accum;
    }, []).join(INNER_CONCATENATION_SEPARATOR)
  }
}
