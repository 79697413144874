import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ContentPresenterComponent } from './components/content-presenter/content-presenter.component';
import { ContentManagerComponent } from './components/content-manager/content-manager.component';
import { PresentStaticComponent } from './components/content-presenter/present-static/present-static.component';
import { PresentSessionSummaryComponent } from './components/content-presenter/present-session-summary/present-session-summary.component';
import { SessionModule } from '../session/session.module';

@NgModule({
  declarations: [
    ContentPresenterComponent,
    ContentManagerComponent,
    PresentStaticComponent,
    PresentSessionSummaryComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SessionModule
  ]
})
export class ContentModule { }
