import { Component } from "@angular/core";
import packageInfo from "../../../../package.json";

@Component({
  selector: "app-version",
  templateUrl: "./app-version.component.html",
  styleUrls: ["./app-version.component.less"],
})
export class AppVersionComponent {
  public appVersion = packageInfo.version;
}
