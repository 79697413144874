import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { BillingFormat, BillingFormatMap } from 'src/app/utils/billing-formats';
import { BillingMethod } from 'src/app/utils/billing-methods';
import { PaymentMethod, PaymentMethodMap } from 'src/app/utils/payment-methods';

interface SelectionButton {
  title: string;
  icon: string;
  selected: boolean;
  enabled: boolean;
  method: PaymentMethod | BillingFormat
}

interface PaymentMethodChanges {
  paymentMethod: PaymentMethod | undefined;
  billingFormat: BillingFormat | undefined
}

type PaymentMethodSettings = Record<PaymentMethod | BillingMethod, { default: undefined | PaymentMethod | BillingFormat, enabled: Array<PaymentMethod | BillingFormat> }>;

const BILLING_METHOD_PAYMENT_METHOD_SETTINGS: Record<string, Partial<PaymentMethodSettings>> = {
  paymentMethodSettings: {
    [BillingMethod.BILL]: {
      default: PaymentMethod.CREDIT_CARD,
      enabled: [PaymentMethod.BANK_TRANSFER, PaymentMethod.CREDIT_CARD, PaymentMethod.CASH],
    },
    [BillingMethod.RECEIPT]: {
      default: PaymentMethod.CREDIT_CARD,
      enabled: [PaymentMethod.CREDIT_CARD, PaymentMethod.CASH]
    },
    [BillingMethod.BILL_COLLECTION]: {
      default: undefined,
      enabled: [],
    }
  },
  billingFormatSettings: {
    [BillingMethod.BILL]: {
      default: BillingFormat.ELECTRONIC,
      enabled: [BillingFormat.ELECTRONIC, BillingFormat.PAPER],
    },
    [BillingMethod.BILL_COLLECTION]: {
      default: undefined,
      enabled: [],
    },
    [BillingMethod.RECEIPT]: {
      default: BillingFormat.RECEIPT,
      enabled: [BillingFormat.RECEIPT],
    }
  }
}

const ASSET_PATH = '/assets/payment-methods';

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.less']
})
export class PaymentMethodComponent implements OnChanges {

  @Output() toggleValue: EventEmitter<PaymentMethodChanges> = new EventEmitter();

  // Available buttons
  public paymentMethodButtons: Array<SelectionButton> = [
    {
      title: PaymentMethodMap.CREDIT_CARD,
      method: PaymentMethod.CREDIT_CARD,
      enabled: true,
      selected: false,
      icon: `${ASSET_PATH}/credit-card.svg`
    },
    {
      title: PaymentMethodMap.CASH,
      method: PaymentMethod.CASH,
      enabled: true,
      selected: false,
      icon: `${ASSET_PATH}/cash.svg`
    },
    {
      title: PaymentMethodMap.BANK_TRANSFER,
      method: PaymentMethod.BANK_TRANSFER,
      enabled: true,
      selected: false,
      icon: `${ASSET_PATH}/transfer.svg`
    },
    {
      title: PaymentMethodMap.VOUCHER,
      method: PaymentMethod.VOUCHER,
      enabled: true,
      selected: false,
      icon: `${ASSET_PATH}/voucher.svg`
    },
    {
      title: PaymentMethodMap.CHIP_CARD,
      method: PaymentMethod.CHIP_CARD,
      enabled: true,
      selected: false,
      icon: `${ASSET_PATH}/chip-card.svg`
    }
  ];

  public billingFormatButtons: Array<SelectionButton> = [
    {
      title: BillingFormatMap.ELECTRONIC,
      method: BillingFormat.ELECTRONIC,
      selected: false,
      enabled: true,
      icon: `${ASSET_PATH}/electronic-format-bill.svg`
    },
    {
      title: BillingFormatMap.PAPER,
      method: BillingFormat.PAPER,
      selected: false,
      enabled: true,
      icon: `${ASSET_PATH}/paper-format-bill.svg`
    },
    {
      title: 'Blokk',
      method: BillingFormat.RECEIPT,
      selected: false,
      enabled: true,
      icon: `${ASSET_PATH}/receipt-format-bill.svg`
    },
  ];

  // Selected method and format values
  public activePaymentMethod: PaymentMethod | undefined = undefined;
  public activeBillingFormat: BillingFormat | undefined = undefined;
  public activeBillingMethod: BillingMethod | undefined = undefined;

  // Enumerations in component
  public billingMethods = BillingMethod;
  public billingFormats = BillingFormat;
  public paymentMethods = PaymentMethod;

  // Input values of component
  @Input() paymentMethod: PaymentMethod | undefined;
  @Input() billingFormat: BillingFormat | undefined;
  @Input() billingMethod: BillingMethod | undefined;

  private latestChangesPublished: PaymentMethodChanges;

  ngOnChanges(): void {
    this.applySettings();
  }

  public publishChanges(): void {

    const payload = {
      paymentMethod: this.activePaymentMethod,
      billingFormat: this.activeBillingFormat
    };

    if (JSON.stringify(this.latestChangesPublished) === JSON.stringify(payload)) {
      return;
    }

    this.toggleValue.emit(payload);

    this.latestChangesPublished = payload;
  }

  public applySettings(): void {

    const paymentMethodSettings = BILLING_METHOD_PAYMENT_METHOD_SETTINGS['paymentMethodSettings'][this.billingMethod as BillingMethod];
    const billingFormatSettings = BILLING_METHOD_PAYMENT_METHOD_SETTINGS['billingFormatSettings'][this.billingMethod as BillingMethod];

    // reset active payment method and billing format
    this.activePaymentMethod = undefined;
    this.activeBillingFormat = undefined;

    this.paymentMethodButtons.forEach((button) => {
      button.selected = false;
      button.enabled = paymentMethodSettings?.enabled.includes(button.method) || false;
      if (button.enabled) {
        button.selected = this.paymentMethod && paymentMethodSettings?.enabled.includes(this.paymentMethod) ? button.method === this.paymentMethod : paymentMethodSettings?.default === button.method;
        if (button.selected) {
          this.activePaymentMethod = button.method as PaymentMethod;
        }
      }
    });

    this.billingFormatButtons.forEach((button) => {
      button.selected = false;
      button.enabled = billingFormatSettings?.enabled.includes(button.method) || false;
      if (button.enabled) {
        button.selected = this.billingFormat && billingFormatSettings?.enabled.includes(this.billingFormat) ? button.method === this.billingFormat : billingFormatSettings?.default === button.method;
        if (button.selected) {
          this.activeBillingFormat = button.method as BillingFormat;
        }
      }
    });

    this.publishChanges();

  }

  public resetSelectedButton(buttons: Array<SelectionButton>): void {
    buttons.forEach((button) => button.selected = false)
  }

  public setActivePaymentMethod(button: SelectionButton): void {
    if (!button.enabled) { return; }
    this.activePaymentMethod = button.method as PaymentMethod;
    this.resetSelectedButton(this.paymentMethodButtons);
    button.selected = true;
    this.publishChanges();
  }

  public setActiveBillingFormat(button: SelectionButton): void {
    if (!button.enabled) { return; }
    this.activeBillingFormat = button.method as BillingFormat;
    this.resetSelectedButton(this.billingFormatButtons);
    button.selected = true;
    this.publishChanges();
  }

}
